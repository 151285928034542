import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { downloadAUrl } from 'better-file-downloads';
import { assets, images } from '@/config';
import orange_140 from '../../../img/banner_orange@140x140.png';
import orange_275 from '../../../img/banner_orange@275x60.png';
import white_140 from '../../../img/banner_white@140x140.png';
import white_275 from '../../../img/banner_white@275x60.png';

// バナーダウンロードのリンクはどのブラウザでもDLできるようにblobのurlを利用しています。
// バナー画像のダウンロードリンクを追加する際はimageURLsに追加して、使用したい箇所で「imageBlobUrls.key」として下さい。
const imageURLs = {
  orange_140: orange_140,
  orange_275: orange_275,
  white_140: white_140,
  white_275: white_275,
};

const imageBlobUrls = {};
for (const image in imageURLs) {
  fetch(imageURLs[image])
    .then(function (response) {
      return response.blob();
    })
    .then(function (imageBlob) {
      imageBlobUrls[image] = URL.createObjectURL(imageBlob);
    });
}

function IntroductionBanner() {
  return (
    <div className="app__banner">
      <div className="b__banner">
        <div className="w__header_label">
          <div className="w__header">
            <h3>バナー使用について</h3>
          </div>
          <p>
            {/* eslint-disable max-len */}
            貴社のホームページに、ぜひFruit
            Saleへのリンクバナーをご掲載ください。 使用する際の連絡は不要です。
            <br />
            <span>※画像は長押しで保存できます。</span>
            {/* eslint-enable max-len */}
          </p>
        </div>
        <div className="w__banner_lists_wrapper">
          <div className="w__banner_lists">
            <h4>■サイズ： 140 × 140px</h4>
            <div className="w__banner_wrapper">
              <div className="w__square_banner">
                <img src={images.banner_1} />
                <Button
                  as="a"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    await downloadAUrl(
                      `${window.location.origin}/assets/logo/banner_white@140x140.png`,
                      {
                        fileName: 'banner_white@140x140',
                        extension: '.png',
                        contentType: 'application/png',
                      }
                    );
                  }}
                >
                  <img
                    src={assets.download_icon}
                    className="w__download_icon"
                  />
                  ダウンロード
                </Button>
              </div>
              <div className="w__square_banner">
                <img src={images.banner_2} />
                <Button
                  as="a"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    await downloadAUrl(
                      `${window.location.origin}/assets/logo/banner_orange@140x140.png`,
                      {
                        fileName: 'banner_orange@140x140',
                        extension: '.png',
                        contentType: 'application/png',
                      }
                    );
                  }}
                >
                  <img
                    src={assets.download_icon}
                    className="w__download_icon"
                  />
                  ダウンロード
                </Button>
              </div>
            </div>
          </div>

          <div className="w__banner_lists">
            <h4>■サイズ： 275 × 60px</h4>
            <div className="w__banner_wrapper">
              <div className="w__rectangle_banner">
                <img src={images.banner_3} />
                <Button
                  as="a"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    await downloadAUrl(
                      `${window.location.origin}/assets/logo/banner_white@275x60.png`,
                      {
                        fileName: 'banner_white@275x60',
                        extension: '.png',
                        contentType: 'application/png',
                      }
                    );
                  }}
                >
                  <img
                    src={assets.download_icon}
                    className="w__download_icon"
                  />
                  ダウンロード
                </Button>
              </div>
              <div className="w__rectangle_banner">
                <img src={images.banner_4} />
                <Button
                  as="a"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    await downloadAUrl(
                      `${window.location.origin}/assets/logo/banner_orange@275x60.png`,
                      {
                        fileName: 'banner_orange@275x60',
                        extension: '.png',
                        contentType: 'application/png',
                      }
                    );
                  }}
                >
                  <img
                    src={assets.download_icon}
                    className="w__download_icon"
                  />
                  ダウンロード
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroductionBanner;

import { CamelDetailType, saleDetailType } from '@/types';
import { formatDate } from '@/utils';

export const snakeToCamel = (data: saleDetailType): CamelDetailType => {
  return {
    id: data.id,
    saleId: data.sale_id,
    breed: data.breed,
    producer: data.producer,
    size: data.size,
    weight: data.weight,
    weightAdditionalInfo: data.weight_additional_info,
    grade: data.grade,
    origin: data.origin,
    originAdditionalInfo: data.origin_additional_info,
    averageSugarContent: data.average_sugar_content,
    harvestDate: formatDate(data.harvest_date),
    harvestTiming: data.harvest_timing,
    saveMethodSeller: data.save_method_seller,
    saveMethodUser: data.save_method_user,
    saveMethodUserAdditionalInfo: data.save_method_user_additional_info,
    cultivationMethod: data.cultivation_method,
    soil: data.soil,
    soilAdditionalInfo: data.soil_additional_info,
    pesticides: data.pesticides,
    genetic: data.genetic,
    jas: data.jas,
    individualObligations: data.individual_obligations,
    globalGap: data.global_gap,
    sizeText: data.size_text,
    gradeText: data.grade_text,
    originText: data.origin_text,
    harvestTimingText: data.harvest_timing_text,
    saveMethodSellerText: data.save_method_seller_text,
    saveMethodUserText: data.save_method_user_text,
    cultivationMethodText: data.cultivation_method_text,
    soilText: data.soil_text,
    soilAdditionalInfoTexts: data.soil_additional_info_texts,
    pesticidesText: data.pesticides_text,
    pesticidesAdditionalInfoText: data.pesticides_additional_info_text,
    geneticText: data.genetic_text,
    jasText: data.jas_text,
  };
};

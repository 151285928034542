import React from 'react';

const ChangeSalesAccount = () => {
  return (
    <div className="a__introduction_change_sales_account">
      <div className="w__introduction_change_sales_account">
        <header>
          売上げ受取口座と
          <span>
            <br />
          </span>
          入金スケジュールの
          <br />
          変更方法について
        </header>
        <div className="change_sale_account_example">
          <img
            src="https://fruitsale.jp/assets/bank_account_explanation_1.png"
            loading="lazy"
            decoding="async"
          />
          <img
            src="https://fruitsale.jp/assets/bank_account_explanation_2.png"
            loading="lazy"
            decoding="async"
          />
          <img
            src="https://fruitsale.jp/assets/bank_account_explanation_3.png"
            loading="lazy"
            decoding="async"
          />
          <img
            src="https://fruitsale.jp/assets/bank_account_explanation_4.png"
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeSalesAccount;

import { connect } from 'react-redux';
import notify from '@/migration/notification';
import { push } from 'connected-react-router';
import {
  compose,
  setDisplayName,
  withStateHandlers,
  withHandlers,
  lifecycle,
  withState,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form/immutable';
import { sale as validate } from '@/validates';
import { bindActionCreators } from 'redux';
import { salesAction, usersAction } from '@/actions';
import { errorTypes } from '@/config';
import { constants } from '../../../../constants';
import { getAllShippingCosts } from '@/apis';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, ...usersAction, push }, dispatch);

const enhance = compose(
  setDisplayName('BidButton'),
  withRouter,
  reduxForm({
    form: 'bidButton',
    initialValues: {
      address: '',
      amount: '',
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('shippingCost', 'setShippingCost', 0),
  withState('coolDeliveryCost', 'setCoolDeliveryCost', 0),
  withState('shippingRelayCost', 'setShippingRelayCost', 0),
  withStateHandlers(
    {
      isOpenModal: false,
      isOpenConfirmModal: false,
      isOpenBuyItNowModal: false,
      isLoading: false,
    },
    {
      handleModal: (state) => (value) => ({
        isLoading: false,
        isOpenModal: typeof value === 'boolean' ? value : !state.isOpenModal,
      }),
      handleConfirmModal: (state) => (value) => ({
        isLoading: false,
        isOpenConfirmModal:
          typeof value === 'boolean' ? value : !state.isOpenConfirmModal,
      }),
      handleBuyItNowModal: (state) => (value) => ({
        isLoading: false,
        isOpenBuyItNowModal:
          typeof value === 'boolean' ? value : !state.isOpenBuyItNowModal,
      }),
      updateLoading: () => (value) => ({
        isLoading: typeof value === 'boolean' ? value : true,
      }),
    }
  ),
  withHandlers({
    /** 入札（即決じゃない方） */
    placeBids: (props) => async (Immutablevalues) => {
      const values = Immutablevalues.toJS();
      const {
        sales,
        getSaleComments,
        updateLoading,
        match,
        getSaleById,
        postSaleBid,
        handleModal,
        handleConfirmModal,
        getSaleBids,
        getUserShipping,
        users,
      } = props;
      const { address } = values;

      const found = users.myshippingdata.find((shippingData) => {
        return shippingData.id === Number(address);
      });

      if (!found) {
        notify('送付先住所が選択されていません', 'error', undefined, 5);
        handleModal(false);
        return;
      }

      const { myshippingdata } = users;
      const { id: myshippingdataId } = myshippingdata.find(
        ({ id: myshippingdataAddress }) =>
          Number(address) === myshippingdataAddress
      );

      const id = myshippingdataId;

      updateLoading(true);
      handleModal(false);
      handleConfirmModal(false);

      try {
        await getUserShipping(users.mydata.id);
        //レスポンスのbodyを取得
        const bidResponse = await postSaleBid(
          sales.item.id,
          Number(values.amount),
          id
        );

        //レスポンスのbodyによって、バリデーションの文言を変更させるためのフラグを取得
        const isHigher = bidResponse.value.body === 'higher bid than you';

        if (sales.item.fixed) {
          await getSaleComments(match.params.sale_id, {
            limit: 100,
            page: 0,
          });
        }
        getSaleBids(match.params.sale_id);
        await getSaleById(match.params.sale_id);

        if (Number(values.amount) === Number(sales.item.fixPrice)) {
          notify(
            'おめでとうございます！落札できました！',
            'info',
            undefined,
            5
          );
        } else if (isHigher) {
          notify(
            '入札上限金額が上回っているか同じ金額のユーザーがいました。もう一度、入札してみましょう。',
            'error',
            undefined,
            5
          );
        } else {
          notify('入札しました。', 'info', undefined, 5);
        }
      } catch (error) {
        if (error.message === errorTypes.cannot_bid_to_fixed_sale) {
          notify(
            '誠に申し訳ございません。この商品は他のユーザーに落札されました。',
            'error',
            undefined,
            5
          );
        } else if (
          error.message ===
          errorTypes.must_raise_the_bid_price_higher_than_the_current_bidder
        ) {
          const latestSalesItem = await getSaleById(props.sales.item.id);
          notify(
            `他のユーザーが${latestSalesItem.value.price}円で入札しました。もう一度、入札してください。`,
            'error',
            undefined,
            5
          );
        } else if (error.message === errorTypes.invalid_credit_card) {
          notify(
            '決済に失敗しました。登録クレジットカード情報をご確認ください。',
            'error',
            undefined,
            5
          );
        } else if (error.message === errorTypes.destination_not_found) {
          notify(
            '送付先情報に入力されていない項目があります',
            'error',
            undefined,
            5
          );
        } else if (error.message === errorTypes.shipping_address_is_incorrect) {
          notify(
            '選択した配送先住所から都道府県の情報を取得出来ませんでした。マイページから再度登録し直して頂くと改善される場合があります。',
            'error',
            undefined,
            5
          );
        } else {
          notify(
            '入札に失敗しました。しばらく時間をおいて再度お試しください。',
            'error',
            undefined,
            5
          );
        }
        updateLoading(false);
      }

      updateLoading(false);
      props.reset();
    },
    /** 即決 */
    buyItNow: (props) => async () => {
      const {
        form: {
          bidButton: { values },
        },
        sales,
        getSaleComments,
        updateLoading,
        match,
        getSaleById,
        postSaleBid,
        handleBuyItNowModal,
        getSaleBids,
        users,
      } = props;

      const found = users.myshippingdata.find((shippingData) => {
        return shippingData.id === Number(values.address);
      });

      if (!found) {
        notify('送付先住所が選択されていません', 'error', undefined, 5);
        handleBuyItNowModal(false);
        return;
      }

      updateLoading(true);
      handleBuyItNowModal(false);

      try {
        await postSaleBid(sales.item.id, Number(sales.item.fixPrice), found.id);

        if (sales.item.fixed) {
          await getSaleComments(match.params.sale_id, {
            limit: 100,
            page: 0,
          });
        }
        getSaleBids(match.params.sale_id);
        await getSaleById(match.params.sale_id);

        notify('おめでとうございます！落札できました！', 'info', undefined, 5);
      } catch (error) {
        console.info(error);
        if (error.message === errorTypes.cannot_bid_to_fixed_sale) {
          notify(
            '誠に申し訳ございません。この商品は他のユーザーに落札されました。',
            'error',
            undefined,
            5
          );
        } else if (error.message === errorTypes.invalid_credit_card) {
          notify(
            '決済に失敗しました。登録クレジットカード情報をご確認ください。',
            'error',
            undefined,
            5
          );
        } else if (error.message === errorTypes.destination_not_found) {
          notify(
            '落札した商品の送付先情報に入力されていない項目があります',
            'error',
            undefined,
            5
          );
        } else if (error.message === errorTypes.shipping_address_is_incorrect) {
          notify(
            '選択した配送先住所から都道府県の情報を取得出来ませんでした。マイページから再度登録し直して頂くと改善される場合があります。',
            'error',
            undefined,
            5
          );
        } else {
          notify(
            '落札に失敗しました。しばらく時間をおいて再度お試しください。',
            'error',
            undefined,
            5
          );
        }
        updateLoading(false);
      }

      updateLoading(false);
      props.reset();
    },

    /** 入札モーダル */
    handleOpenBidModal: (props) => async () => {
      const {
        form,
        handleModal,
        handleConfirmModal,
        sales,
        users: { myshippingdata },
        match: {
          params: { sale_id },
        },
        setShippingCost,
        setCoolDeliveryCost,
        setShippingRelayCost,
      } = props;
      const {
        bidButton: {
          values: { address },
        },
      } = form;

      const found = myshippingdata.find((shippingData) => {
        return shippingData.id === Number(address);
      });

      if (!found) {
        notify('送付先住所が選択されていません', 'error', undefined, 5);
        return;
      }

      const { id } = myshippingdata.find(
        ({ id: myshippingdataAddress }) =>
          Number(address) === myshippingdataAddress
      );

      const { shipping_cost, cool_delivery_cost, shipping_relay_cost } =
        await getAllShippingCosts(id, sale_id);

      setShippingCost(shipping_cost);
      setCoolDeliveryCost(cool_delivery_cost);
      setShippingRelayCost(shipping_relay_cost);

      if (form.bidButton.values.amount) {
        if (window.document.activeElement) {
          window.document.activeElement.blur();
        }
        if (Number(form.bidButton.values.amount) === sales.item.fixPrice) {
          handleConfirmModal();
        } else {
          handleModal();
        }
      }
    },

    /** 即決モーダル */
    handleOpenBuyItNowModal: (props) => async () => {
      const {
        form,
        handleBuyItNowModal,
        users: { myshippingdata },
        match: {
          params: { sale_id },
        },

        setShippingCost,
        setCoolDeliveryCost,
        setShippingRelayCost,
      } = props;
      if (window.document.activeElement) {
        window.document.activeElement.blur();
      }

      const {
        bidButton: {
          values: { address },
        },
      } = form;

      const found = myshippingdata.find((shippingData) => {
        return shippingData.id === Number(address);
      });

      if (!found) {
        notify('送付先住所が選択されていません', 'error', undefined, 5);
        return;
      }

      const { id } = myshippingdata.find(
        ({ id: myshippingdataAddress }) =>
          Number(address) === myshippingdataAddress
      );

      const { shipping_cost, cool_delivery_cost, shipping_relay_cost } =
        await getAllShippingCosts(id, sale_id);

      setShippingCost(shipping_cost);
      setCoolDeliveryCost(cool_delivery_cost);
      setShippingRelayCost(shipping_relay_cost);

      handleBuyItNowModal();
    },
    validateAmount: (props) => (value) => {
      if (!/^[0-9]+$/.test(value)) {
        return '半角数字を入力してください';
      }
      if (value <= props.sales.item.displayPrice) {
        return (
          (props.sales.item.displayPrice + 1).toLocaleString() +
          '円以上の金額を入力してください'
        );
      } else if (
        value > constants.PRICES.MAX_FIX_PRICE &&
        props.sales.item.fixPrice > constants.PRICES.MAX_FIX_PRICE
      ) {
        return `${constants.PRICES.MAX_FIX_PRICE.toLocaleString()}円以下の金額を入力して下さい`;
      } else if (value > props.sales.item.fixPrice) {
        return '即決価格以下の金額を入力してください';
      }
      return null;
    },
    handleCheckValidate: (props) => () => {
      props.touch('amount');
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.reset();
    },
  })
);

export default enhance;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import notify from '@/migration/notification';
import { reduxForm } from 'redux-form/immutable';
import {
  compose,
  setDisplayName,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import { usersAction } from '@/actions';

import {
  updateUserEmailSetting,
  getUserEmailSetting,
} from '../../../../../apis';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...usersAction,
    },
    dispatch
  );
};

const enhance = compose(
  setDisplayName('emailConfig'),
  reduxForm({
    form: 'emailConfigForm',
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    submit: (props) => async () => {
      const {
        form: { emailConfigForm },
        handleCloseModal,
        userId,
        setIsLoading,
      } = props;

      const {
        anotherUserBidded,
        anotherUserFixedSale,
        sallerWithDrawSale,
        followUserSell,
        reviewReceived,
        reviewReplyCommentReceived,
      } = emailConfigForm.values;

      try {
        setIsLoading(true);
        await updateUserEmailSetting(userId, {
          is_another_user_bidded_email_wanted: Boolean(anotherUserBidded),
          is_another_user_fixed_sale_email_wanted:
            Boolean(anotherUserFixedSale),
          is_seller_withdraw_sale_email_wanted: Boolean(sallerWithDrawSale),
          is_follow_user_sell_email_wanted: Boolean(followUserSell),
          is_review_received_email_wanted: Boolean(reviewReceived),
          is_review_reply_comment_received_email_wanted: Boolean(
            reviewReplyCommentReceived
          ),
        });
        notify('メール通知の設定を保存しました。', 'info', undefined, 5);
      } catch (error) {
        notify('メール通知の設定に失敗しました。', 'error', undefined, 5);
      } finally {
        setIsLoading(false);
        handleCloseModal(false);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        users: { mydata },
        initialize,
      } = this.props;
      try {
        const {
          is_another_user_bidded_email_wanted,
          is_another_user_fixed_sale_email_wanted,
          is_seller_withdraw_sale_email_wanted,
          is_follow_user_sell_email_wanted,
          is_review_received_email_wanted,
          is_review_reply_comment_received_email_wanted,
        } = await getUserEmailSetting(mydata.id);

        initialize({
          anotherUserBidded: is_another_user_bidded_email_wanted,
          anotherUserFixedSale: is_another_user_fixed_sale_email_wanted,
          sallerWithDrawSale: is_seller_withdraw_sale_email_wanted,
          followUserSell: is_follow_user_sell_email_wanted,
          reviewReceived: is_review_received_email_wanted,
          reviewReplyCommentReceived:
            is_review_reply_comment_received_email_wanted,
        });
      } catch (error) {
        console.error(error);
      }
    },
  })
);

export default enhance;

import * as React from 'react';
import enhance from './enhancer';
import { ReviewItem } from '../ReviewItem/';
import { Icon, Item } from 'semantic-ui-react';
import Rating from 'react-rating';

function ReviewList(props) {
  const { averagePoint, totalEvaluationCount, data, isLinkDisabled, current } =
    props;

  return (
    <div className="reviewList">
      <div className="reviewHeader">
        <span id="reputation_av">
          {averagePoint}
          <Rating
            initialRating={averagePoint}
            emptySymbol={<Icon name="star" color="grey" />}
            fullSymbol={<Icon name="star" color="yellow" />}
            readonly={true}
          />
        </span>
        <span className="reviewNum">（{totalEvaluationCount}件の評価）</span>
      </div>
      <Item.Group divided>
        {data.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <ReviewItem
                id={item.id}
                distUserId={item.distUserId}
                point={item.point}
                sourceUserID={item.sourceUserID}
                comment={item.comment}
                name={item.isCompany ? item.company : item.displayName}
                icon={item.avatarUrl}
                isLinkDisabled={isLinkDisabled}
                reply={item.reply}
                current={current}
                fishKind={item.name}
                saleId={item.saleId}
              />
            </React.Fragment>
          );
        })}
      </Item.Group>
    </div>
  );
}

export default enhance((props) => <ReviewList {...props} />);

import React from 'react';
import { Field } from 'redux-form/immutable';

import Accordion from '../Accordion';
import BetweenInput from '../BetweenInput';
import SideNavDropDown from '../DropDown';
import SearchInput from '../SearchInput';
import Checkbox from '../../../Form/FieldCheckbox';
import Radio from '../../../Form/FieldRadio/FieldRadio';

import ikejimeMethods from '../../../../config/app/ikejimeMethod';
import cleaningMethods from '../../../../config/app/cleaningMethod';
import savingMethods from '../../../../config/app/savingMethod';
import shippingExpenses from '../../../../config/app/shippingExpense';
import deliveryMethods from '../../../../config/app/deliveryMethod';
import shippingTimings from '../../../../config/app/shippingTiming';
import ikejimeTimings from '../../../../config/app/ikejimeTiming';

import { app } from '@/config';

import { statusCategory, areas, sorts } from '../data';

// TODO:絞り込み検索
/** すべてと個人出品の検索 */
const { soil, pesticides, grade, saveMethod, cultivationMethod, genetic, jas } =
  app;

const AllSearch = ({ submit }) => (
  <>
    <Field name="keyword" component={SearchInput} submit={submit} />

    <ul className="b__sidenav__list">
      <SideNavDropDown
        name="status"
        label="対象商品"
        placeholder="カテゴリ"
        options={statusCategory}
      />

      <SideNavDropDown
        name="area"
        label="地域"
        placeholder="地域"
        options={areas}
      />

      <BetweenInput
        label="商品サイズ"
        smallName="min_size"
        largeName="max_size"
        maxLength="6"
      />

      <BetweenInput
        label="商品重量"
        smallName="min_weight"
        largeName="max_weight"
        maxLength="6"
      />

      <BetweenInput
        label="商品価格"
        smallName="min_price"
        largeName="max_price"
        maxLength="7"
        isPrice
      />

      <BetweenInput
        label="即決価格"
        smallName="min_fix_price"
        largeName="max_fix_price"
        maxLength="7"
        isPrice
      />

      {/* 等級 */}
      <Accordion id="grade" title="等級" data={grade} component={Checkbox} />

      {/* 栽培方法 */}
      <Accordion
        id="cultivation_method"
        title="栽培方法"
        data={cultivationMethod}
        component={Checkbox}
      />

      {/* 土壌 */}
      <Accordion id="soil" title="土壌" data={soil} component={Checkbox} />

      {/* 農薬 */}
      <Accordion
        id="pesticides"
        title="農薬"
        data={pesticides}
        component={Checkbox}
      />

      {/* 出品者側保管方法 */}
      <Accordion
        id="save_method_seller"
        title="出品者側保管方法"
        data={saveMethod}
        component={Checkbox}
      />

      {/* ご家庭での推奨保管方法 */}
      <Accordion
        id="save_method_user"
        title="ご家庭での推奨保管方法"
        data={saveMethod}
        component={Checkbox}
      />

      {/* 遺伝子組み換え */}
      <Accordion
        id="genetic"
        title="遺伝子組み換え"
        data={genetic}
        component={Checkbox}
      />

      {/* 有機JAS認定 */}
      <Accordion id="jas" title="有機JAS認定" data={jas} component={Checkbox} />

      {/* 締め方 */}
      {/* <Accordion
        id="ikejime_method"
        title="締め方"
        data={ikejimeMethods}
        component={Checkbox}
      /> */}

      {/* 処理方法 */}
      {/* <Accordion
        id="cleaning_method"
        title="処理方法"
        data={cleaningMethods}
        component={Checkbox}
      /> */}

      {/* 締め処理を行ったタイミング */}
      {/* <Accordion
        id="ikejime_timing"
        title="締め処理を行ったタイミング"
        data={ikejimeTimings}
        component={Radio}
        propsName="ikejime_timing"
      /> */}

      {/* 分類 */}
      {/* <li className="b__sidenav__listitem">
        <div className="b__sidenav__accordion">
          <input
            type="checkbox"
            id="catalog"
            className="b__sidenav__accordion-hidden"
          />
          <label htmlFor="catalog" className="b__sidenav__accordion-title">
            分類
          </label>
          <div className="b__sidenav__accordion-content">
            <Field name="is_nature" label="天然のみ表示" component={Checkbox} />
            <Field name="is_new" label="新着のみ表示" component={Checkbox} />
            <Field
              name="is_immediately"
              label="即決のみ表示"
              component={Checkbox}
            />
          </div>
        </div>
      </li> */}

      {/* 保管方法 */}
      {/* <Accordion
        id="saving_method"
        title="保管方法"
        data={savingMethods}
        component={Checkbox}
      /> */}

      {/* 配送料の負担 */}
      <Accordion
        id="shipping_expense"
        title="配送料の負担"
        data={shippingExpenses}
        component={Checkbox}
      />

      {/* 配送料の負担 */}
      <Accordion
        id="delivery_method"
        title="配送方法"
        data={deliveryMethods}
        component={Checkbox}
      />

      {/* 発送日数 */}
      <Accordion
        id="shipping_timing"
        title="発送日数"
        data={shippingTimings}
        component={Checkbox}
      />

      <div className="border" />

      <SideNavDropDown
        name="sort"
        label="並び替え"
        placeholder="入札件数の多い順"
        options={sorts}
      />
    </ul>
  </>
);

export default AllSearch;

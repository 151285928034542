import React from 'react';

import { Button } from 'semantic-ui-react';

import ViewSection from './ViewSection';
import DetailSection from './DetailSection';
import { useModal } from './useModas';

import './style.scss';

const SellConfirmModal = (props) => {
  const {
    previewData,
    mydata,
    confirmSubmit,
    loading,
    showErrorMessage,
    setShowErrorMessage,
  } = props;
  useModal();

  return (
    <div className="modal__sell-confirm">
      <div className="modal__sell-confirm__main">
        <ViewSection
          price={previewData?.price}
          fishKind={previewData?.fish_kind}
          images={previewData?.images}
          closeBidAt={previewData?.close_bid_at}
        />
        {/* TODO:フォームの項目追加 */}
        <DetailSection
          sellerName={mydata.isCompany ? mydata.company : mydata.displayName}
          isCompany={mydata.isCompany}
          prefecture={mydata.prefecture}
          size={previewData?.size}
          weight={previewData?.weight}
          weightAdditionalInfo={previewData?.weight_additional_info}
          spotText={previewData?.spot_text}
          fishingSpotDetail={previewData?.fishing_spot_detail}
          fishingDay={previewData?.fishing_day}
          isNature={previewData?.is_nature}
          methodText={previewData?.method_text}
          isIkejime={previewData?.is_ikejime}
          cleaningText={previewData?.cleaning_text}
          waterText={previewData?.water_text}
          isVacuum={previewData?.is_vacuum}
          saveText={previewData?.save_text}
          expenseText={previewData?.expense_text}
          shippingProvider={mydata?.shippingProvider}
          deliveryText={previewData?.delivery_text}
          ikejimeText={previewData?.ikejime_text}
          timingText={previewData?.timing_text}
          area={previewData?.area_text}
          fixPrice={previewData?.fix_price}
          shippingExpense={previewData?.shipping_expense}
          shippingSize={previewData?.shipping_size}
          isLandAfterFix={previewData?.is_land_after_fix}
          explanation={previewData?.explanation}
          // 追加
          name={previewData?.name}
          breed={previewData?.breed}
          isUnevenness={previewData?.is_unevenness}
          producer={previewData?.producer}
          grade={previewData?.grade_text}
          origin={previewData?.origin_text}
          originAdditionalInfo={previewData?.origin_additional_info}
          averageSugarContent={previewData?.average_sugar_content}
          harvestTiming={previewData?.harvest_timing_text}
          harvestDate={previewData?.harvest_date}
          saveMethodSeller={previewData?.save_method_seller_text}
          saveMethodUser={previewData?.save_method_user_text}
          saveMethodUserAdditionalInfo={
            previewData?.save_method_user_additional_info
          }
          cultivationMethod={previewData?.cultivation_method_text}
          soil={previewData?.soil_text}
          soilAdditionalInfo={previewData?.soil_additional_info_texts}
          pesticides={previewData?.pesticides_text}
          pesticidesAdditionalInfo={
            previewData?.pesticides_additional_info_text
          }
          genetic={previewData?.genetic_text}
          jas={previewData?.jas_text}
          individualObligations={previewData?.individual_obligations}
          globalGap={previewData?.global_gap}
        />
      </div>

      <div className="modal__sell-confirm__main-action">
        {showErrorMessage && (
          <div className="modal__sell-confirm__error-message">
            <span
              onClick={() => {
                setShowErrorMessage('');
              }}
            >
              <img
                src={
                  'https://s3-ap-northeast-1.amazonaws.com/assets.gemcook.com/icons/close.svg'
                }
              />
            </span>

            <div>
              <h3>出品エラー</h3>
              <p>{showErrorMessage}</p>
            </div>
          </div>
        )}

        <Button
          positive
          fluid
          onClick={confirmSubmit}
          loading={loading}
          disabled={loading}
        >
          出品する
        </Button>
      </div>
    </div>
  );
};

export default SellConfirmModal;

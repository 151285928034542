const applyStripeEnv = () => {
  const ndenv = process.env.REACT_APP_ENV_CODE;

  if (ndenv === 'local') {
    return {
      ApiKey: 'pk_test_GEjjSy1vUs3evFD4Cw3VwBeq',
      ConnectUrl:
        'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_C8lCsgfrLcU8bMyjUSjiMyhisWkOiWyr&scope=read_write&redirect_uri=http://localhost:1110/stripe-callback&stripe_user[url]=https%3a%2f%2ffishsale%2ejp&stripe_user[product_description]=%E9%87%A3%E3%81%A3%E3%81%9F%E9%AD%9A%E3%82%92%E3%81%9D%E3%81%AE%E5%A0%B4%E3%81%A7%E5%87%BA%E5%93%81%E3%81%97%E3%81%A6%E3%80%81%0d%0a%E3%81%BB%E3%81%97%E3%81%84%E6%99%82%E3%81%AB%E3%81%BB%E3%81%97%E3%81%84%E4%BA%BA%E3%81%AB%E5%B1%8A%E3%81%91%E3%82%8B%0d%0a%E9%AD%9A%E5%B0%82%E9%96%80%E3%82%BB%E3%83%AA%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%81%99&stripe_user[business_type]=sole_prop',
    };
  }

  if (ndenv === 'dev') {
    return {
      ApiKey: 'pk_test_GEjjSy1vUs3evFD4Cw3VwBeq',
      ConnectUrl:
        'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_C8lCsgfrLcU8bMyjUSjiMyhisWkOiWyr&scope=read_write&redirect_uri=https://dev.fruitsale.jp/stripe-callback&stripe_user[url]=https%3a%2f%2ffruitsale%2ejp&stripe_user[product_description]=%E9%87%A3%E3%81%A3%E3%81%9F%E9%AD%9A%E3%82%92%E3%81%9D%E3%81%AE%E5%A0%B4%E3%81%A7%E5%87%BA%E5%93%81%E3%81%97%E3%81%A6%E3%80%81%0d%0a%E3%81%BB%E3%81%97%E3%81%84%E6%99%82%E3%81%AB%E3%81%BB%E3%81%97%E3%81%84%E4%BA%BA%E3%81%AB%E5%B1%8A%E3%81%91%E3%82%8B%0d%0a%E9%AD%9A%E5%B0%82%E9%96%80%E3%82%BB%E3%83%AA%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%81%99&stripe_user[business_type]=sole_prop',
    };
  }

  if (ndenv === 'stg') {
    return {
      ApiKey: 'pk_test_GEjjSy1vUs3evFD4Cw3VwBeq',
      ConnectUrl:
        'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_C8lCsgfrLcU8bMyjUSjiMyhisWkOiWyr&scope=read_write&redirect_uri=https://stg.fruitsale.jp/stripe-callback&stripe_user[url]=https%3a%2f%2ffruitsale%2ejp&stripe_user[product_description]=%E9%87%A3%E3%81%A3%E3%81%9F%E9%AD%9A%E3%82%92%E3%81%9D%E3%81%AE%E5%A0%B4%E3%81%A7%E5%87%BA%E5%93%81%E3%81%97%E3%81%A6%E3%80%81%0d%0a%E3%81%BB%E3%81%97%E3%81%84%E6%99%82%E3%81%AB%E3%81%BB%E3%81%97%E3%81%84%E4%BA%BA%E3%81%AB%E5%B1%8A%E3%81%91%E3%82%8B%0d%0a%E9%AD%9A%E5%B0%82%E9%96%80%E3%82%BB%E3%83%AA%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%81%99&stripe_user[business_type]=sole_prop',
    };
  }

  if (ndenv === 'prd') {
    return {
      ApiKey: 'pk_live_iOL9PWvmILWJyFypmjFpaFur',
      ConnectUrl:
        'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_C8lC1Qx0ksSF7kiLWka59pBrTLNS27kQ&scope=read_write&redirect_uri=https://fruitsale.jp/stripe-callback&stripe_user[url]=https%3a%2f%2ffruitsale%2ejp&stripe_user[product_description]=%E9%87%A3%E3%81%A3%E3%81%9F%E9%AD%9A%E3%82%92%E3%81%9D%E3%81%AE%E5%A0%B4%E3%81%A7%E5%87%BA%E5%93%81%E3%81%97%E3%81%A6%E3%80%81%0d%0a%E3%81%BB%E3%81%97%E3%81%84%E6%99%82%E3%81%AB%E3%81%BB%E3%81%97%E3%81%84%E4%BA%BA%E3%81%AB%E5%B1%8A%E3%81%91%E3%82%8B%0d%0a%E9%AD%9A%E5%B0%82%E9%96%80%E3%82%BB%E3%83%AA%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%81%99&stripe_user[business_type]=sole_prop',
    };
  }

  return '';
};
const StripeConfig = applyStripeEnv();

function configureStripe() {
  return StripeConfig;
}

export default configureStripe;

export const sellerTemplates = [
  {
    key: 1,
    text: '落札いただきありがとうございました。発送準備を行いますので、今しばらくお待ちくださいませ。',
    value:
      '落札いただきありがとうございました。発送準備を行いますので、今しばらくお待ちくださいませ。',
  },
  {
    key: 2,
    text: 'お待たせ致しました。本日発送致しましたので、商品到着まで今しばらくお待ちくださいませ。またの機会には何卒よろしくお願い致します。',
    value:
      'お待たせ致しました。本日発送致しましたので、商品到着まで今しばらくお待ちくださいませ。またの機会には何卒よろしくお願い致します。',
  },
];

export const bidderTemplates = [
  {
    key: 1,
    text: 'よろしくお願いします。楽しみにしています。',
    value: 'よろしくお願いします。楽しみにしています。',
  },
  {
    key: 2,
    text: '商品が無事届きました。ありがとうございました。また機会がございましたらよろしくお願いします。',
    value:
      '商品が無事届きました。ありがとうございました。また機会がございましたらよろしくお願いします。',
  },
];

import React from 'react';

const Styrofoam = () => {
  return (
    <div className="a__introduction_styrofoam">
      <div className="w__introduction_styrofoam">
        <header>発泡スチロール購入先について</header>
        <section>
          <ul>
            <li>
              <span className="b__label">発泡スチロール箱通販.com</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://eps-box.com/"
              >
                https://eps-box.com/
              </a>
            </li>
            <li>
              <span className="b__label">
                発泡スチロール箱・保冷剤販売の小野商店
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.ono-syouten.co.jp/ono/"
              >
                http://www.ono-syouten.co.jp/ono/
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Styrofoam;

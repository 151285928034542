// eslint-disable @typescript-eslint/camelcase

export default {
  common: {
    notFound: '/notFound',
    contact: '/contact',
    law: `https://about.freshspeed.net/law.html`,
    company: 'https://about.freshspeed.net/company.html',
    terms: 'https://about.freshspeed.net/terms.html',
    confirmEmail: '/confirm-email',
    fee: '/fee',
    privacy: 'https://about.freshspeed.net/privacy.html',
    mediaPublication: '/media-publication',
  },
  landing: '/',
  cognitocallback: '/cognito-callback',
  stripecallback: '/stripe-callback',
  policy: {
    root: '/policy',
    terms: '/policy/terms',
    privacy: '/policy/privacy',
  },
  before: {
    signup: '/b/signup',
    signUpThank: '/signup-thank',
    signup_confirm: '/b/signup/confirm',
    signin: '/b/signin',
    resetPassword: '/reset-password',
    confirmPassword: '/confirm-password',
  },
  sell: '/sell',
  mypage: {
    root: '/mypage',
    all: '/mypage/all',
    admin: '/mypage/admin',
    bid_of_bidnow: '/mypage/bid_of_bidnow',
    bid_of_soldout: '/mypage/bid_of_soldout',
    credit: '/mypage/credit',
    credit_register: '/mypage/credit/register',
    bank_account: '/mypage/bank-account',
    edit: '/mypage/edit',
    exhibit_of_exhibitnow: '/mypage/exhibit_of_exhibitnow',
    exhibit_of_serinow: '/mypage/exhibit_of_serinow',
    exhibit_of_soldout: '/mypage/exhibit_of_soldout',
    leave: '/mypage/leave',
    logout: '/mypage/logout',
    notification: '/mypage/notification',
    contact: '/mypage/contact',
    review: '/mypage/rerview',
    unrated: '/mypage/unrated',
    follow: '/mypage/follow',
    follower: '/mypage/follower',
  },
  notificationRedirect: '/notificationRedirect',
  fishes: {
    root: '/sale',
    search: '/sale/search',
    saleItem: '/sale/:sale_id',
    all: '/sale/all',
    company: '/sale/company',
    personal: '/sale/personal',
  },

  introduction: {
    root: '/introduction',
    fee: '/introduction/fee',
    sell: '/introduction/sell',
    account: '/introduction/account',
    cooking: '/introduction/cooking',
    banner: '/introduction/banner',
    card: '/introduction/card',
    styrofoam: '/introduction/styrofoam',
    explain: '/introduction/explain',
    sales_account: '/introduction/sales_account',
    howto_sales_account: '/introduction/howto_sales_account',
    change_sales_account: '/introduction/change_sales_account',
    prepayment: '/introduction/prepayment',
    faq: '/introduction/stripe_faq',
  },
  users: {
    profile: '/users/:user_id',
    follow: '/users/:user_id/follow',
    follower: '/users/:user_id/follower',
  },
};

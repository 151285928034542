import { getSalesDetail } from '@/apis';
import { CamelDetailType } from '@/types';
import { useEffect, useState } from 'react';
import { snakeToCamel } from './utils';

export const useDetailItem = (id: number) => {
  const [detailData, setDetailData] = useState<CamelDetailType>({
    id: 0,
    saleId: 0,
    breed: '',
    producer: '',
    size: 0,
    weight: 0,
    weightAdditionalInfo: '',
    grade: 0,
    origin: 0,
    originAdditionalInfo: '',
    averageSugarContent: 0,
    harvestDate: '0000-00-00',
    harvestTiming: 0,
    saveMethodSeller: 0,
    saveMethodUser: 0,
    saveMethodUserAdditionalInfo: '',
    cultivationMethod: 0,
    soil: 0,
    soilAdditionalInfo: '',
    pesticides: 0,
    genetic: 0,
    jas: 0,
    individualObligations: '',
    globalGap: '',
    sizeText: '',
    gradeText: '',
    originText: '',
    harvestTimingText: '',
    saveMethodSellerText: '',
    saveMethodUserText: '',
    cultivationMethodText: '',
    pesticidesText: '',
    soilText: '',
    soilAdditionalInfoTexts: [],
    pesticidesAdditionalInfoText: '',
    geneticText: '',
    jasText: '',
  });

  useEffect(() => {
    (async () => {
      if (id !== 0) {
        try {
          const { data } = await getSalesDetail(id);
          setDetailData(snakeToCamel(data));
        } catch {
          console.error;
        }
      }
    })();
  }, [id]);

  return { detailData };
};

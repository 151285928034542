const cleaningMethods = [
  {
    key: '10',
    name: 'cleaningMethods10',
    value: 10,
    text: 'なし',
  },
  {
    key: '1',
    name: 'cleaningMethods1',
    value: 1,
    text: '内臓処理',
  },
  {
    key: '2',
    name: 'cleaningMethods2',
    value: 2,
    text: '内臓処理、血わた処理',
  },

  {
    key: '3',
    name: 'cleaningMethods3',
    value: 3,
    text: '血抜き、内臓処理',
  },
  {
    key: '4',
    name: 'cleaningMethods4',
    value: 4,
    text: '血抜き、内臓処理、血わた処理',
  },

  {
    key: '5',
    name: 'cleaningMethods5',
    value: 5,
    text: '血抜き→神経締め、内臓処理',
  },
  {
    key: '6',
    name: 'cleaningMethods6',
    value: 6,
    text: '血抜き→神経締め、内臓処理、血わた処理',
  },
  {
    key: '7',
    name: 'cleaningMethods7',
    value: 7,
    text: '血抜き→神経締め→水抜き、内臓処理',
  },
  {
    key: '8',
    name: 'cleaningMethods8',
    value: 8,
    text: '血抜き→神経締め→水抜き、内臓処理、血わた処理',
  },
  {
    key: '9',
    name: 'cleaningMethods9',
    value: 9,
    text: '津本式・究極の血抜き（血抜き→神経締め→水抜き、内臓処理、血わた処理）',
  },
];

export const cleaningMethodNames = cleaningMethods.map(({ name }) => name);

export default cleaningMethods;

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '@/config';
import { prefectures, coolPrices } from './data';
import { getEnv } from '@/utils';

const { REDIRECT_URL_VENDOR_SHIPPING_COST } = getEnv();

const Prepayment = () => (
  <div className="a__introduction-prepayment">
    <div className="b__main">
      <h1>元払い出品の送料について</h1>
      <h2>元払い</h2>
      <h3>元払い出品が可能になる条件</h3>
      <p>
        元払いを選択して出品するには、通常の出品条件に加えて、以下の条件をすべて満たしている必要があります。
      </p>
      <ul>
        <li>
          マイページ/プロフィール編集から、事業者登録のチェックを入れていること。登録は「
          <Link to={paths.mypage.edit} className="info_link" target="_blank">
            こちら
          </Link>
          」
        </li>
        <li>
          元払い運賃設定を登録していること。登録は「
          <a
            href={REDIRECT_URL_VENDOR_SHIPPING_COST}
            className="info_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          」
        </li>
        <li>
          配送業者を登録していること。登録は「
          <Link to={paths.mypage.edit} className="info_link" target="_blank">
            こちら
          </Link>
          」
        </li>
      </ul>
      <h3>元払い商品の送料計算方法</h3>
      <p>
        決済時に計算される金額 ： 商品代金 ＋ 基本的な送料 ＋ 離島中継料金 ＋
        クール料金
      </p>
      <h2>各送料</h2>
      <h3>基本的な送料について</h3>
      <p>
        配送にかかる基本的な料金です。
        <br />
        送付先地方と配送サイズごとに、送料を元払い運賃設定として登録して頂けます。
        <br />
        出品者は、出品時の配送料の負担を元払いに設定した上で出品して頂くと、落札されたときに
        <b>自動的に基本的な送料が加算された料金で決済</b>されます。
      </p>
      <h3>離島中継料金について</h3>
      <p>
        配送業者が佐川急便で、離島に住んでいる方に配送する際にかかる中継料金です。
        <br />
        料金自体は、全ユーザー共通で、佐川急便の離島中継料金を元に設定されています。
        <br />
        具体的な料金については、
        <Link to={paths.common.fee} className="info_link" target="_blank">
          「元払い運賃の離島料金表」
        </Link>
        をご参照下さい。
        <br />
        元払い指定の商品が、送付先住所を離島のユーザーに落札され、さらに出品者が配送業者を佐川急便に設定していた場合、
        <b>自動的に離島中継料金が加算された料金で決済</b>されます。
      </p>
      <h3>クール料金について</h3>
      <p>
        各配送業者の保冷配送サービスを利用の際にかかる料金です。
        <br />
        出品者は、出品時の配送方法を <b>冷蔵</b> または <b>冷凍</b>
        にした上で <b>元払い</b>
        に設定して出品した場合
        <br />
        落札されたときに<b>自動的にクール料金が加算された料金で決済</b>
        されます。
      </p>

      {coolPrices.map(({ data }, index) => (
        <table key={index}>
          <thead>
            <tr>
              <th>配送業者</th>
              <th>配送サイズ</th>
              <th>料金</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ company, rowSpan, priceSize }) => (
              <React.Fragment key={company}>
                <tr>
                  <td rowSpan={rowSpan}>{company}</td>
                </tr>
                {priceSize.map(({ size, price }) => (
                  <tr key={size}>
                    <td>{size}</td>
                    <td>{price}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ))}
      <h3>配送業者について</h3>
      <p>発送で使用する配送業者を、以下の業者から選べます。</p>
      <ul>
        <li>佐川急便</li>
        <li>ヤマト運輸</li>
        <li>日本郵便</li>
      </ul>
      <h3>元払い運賃設定について</h3>
      <p>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="info_link"
          href={REDIRECT_URL_VENDOR_SHIPPING_COST}
        >
          管理画面
        </a>
        から元払い運賃の送料を設定可能です。
        <br />
        業者登録をしている場合、元払い出品をご利用いただけます。
        <br />
        業者登録は
        <Link to={`/mypage/edit`} className="info_link">
          マイページのプロフィール編集
        </Link>
        より設定頂けます。
      </p>
      <h3>送付先地方について</h3>
      <p>
        送付先地方は日本国内を14地方に分けています。
        <br />
        各都道府県との対応は以下の通りです。
      </p>

      {prefectures.map(({ data }, index) => (
        <table className="prefectures" key={index}>
          <thead>
            <tr>
              <th>地方</th>
              <th>都道府県</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ area, prefectures, rowSpan }, index) => (
              <React.Fragment key={index}>
                {rowSpan === 1 ? (
                  <tr>
                    <td>{area}</td>
                    <td>{prefectures}</td>
                  </tr>
                ) : (
                  <React.Fragment key={index}>
                    <tr>
                      <td rowSpan={rowSpan}>{area}</td>
                    </tr>
                    {prefectures.map((prefecture) => (
                      <tr key={prefecture}>
                        <td>{prefecture}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ))}

      <h3>配送サイズについて</h3>
      <p>
        規定の配送サイズは、以下の通りです。
        <br /> これらの中から選択して下さい。
      </p>
      <ul>
        <li>60</li>
        <li>80</li>
        <li>100</li>
        <li>120</li>
        <li>140</li>
        <li>160</li>
        <li>170</li>
      </ul>
      <h3>クール料金の規定サイズを超過する場合について</h3>
      <p>
        クールの上限サイズを超えた商品が落札された場合など、オプション等の料金が余分にかかる可能性がある場合、Fruit&nbsp;Saleでは元払い運賃設定を高めに設定することで対応して頂くことを推奨しております。
      </p>
    </div>
  </div>
);

export default Prepayment;

import * as React from 'react';
import { Link } from 'react-router-dom';
import enhance from './enhancer';
import { getLocaleStringLocales } from '@/utils';
import { paymentMethod, isSeller, getDeliveryPrices } from '../../../../utils';
import { paths } from '@/config';
import { replaceTofu } from '@/utils/string';
import { useDetailItem } from '@/hooks';

// const INVALID_DATE = 'Invalid date';

function SaleInfoList(props) {
  const {
    sales: {
      item: {
        sellerUserId,
        sellerName,
        postedAt,
        fixPrice,
        deliveryText,
        area,
        expenseText,
        shippingExpense,
        shippingSize,
        shippingProvider,
        // isNature,
        // isIkejime,
        // fishingSpot,
        // fishingSpotDetail,
        // methodText,
        // waterText,
        // ikejimeTimingText,
        // fishingDay,
        // saveText,
        // isVacuum,
        // cleaningText,
        // isLandAfterFix,
        timingText,
      },
    },
    users: {
      mydata: { id, prefecture },
    },
  } = props;

  const { detailData } = useDetailItem(parseInt(props.sales.item.id));

  // 追加
  const {
    size,
    weight,
    weightAdditionalInfo,
    sizeText,
    producer,
    breed,
    gradeText,
    originText,
    originAdditionalInfo,
    averageSugarContent,
    harvestDate,
    harvestTimingText,
    saveMethodSellerText,
    saveMethodUserText,
    saveMethodUserAdditionalInfo,
    cultivationMethodText,
    soilText,
    soilAdditionalInfoTexts,
    pesticidesText,
    pesticidesAdditionalInfoText,
    geneticText,
    jasText,
    individualObligations,
    globalGap,
  } = detailData;

  // let fishingDate = '';
  // if (fishingDay === INVALID_DATE) {
  //   fishingDate = '-';
  // } else {
  //   fishingDate = fishingDay;
  // }

  // 落札後水揚げにチェックが付いていれば、落札後水揚げを表示する
  // if (isLandAfterFix) {
  //   fishingDate = '落札後水揚げ';
  // }

  let shippingProviderName = '';

  if (shippingProvider === 1) {
    shippingProviderName = '佐川急便';
  } else if (shippingProvider === 2) {
    shippingProviderName = 'ヤマト運輸';
  } else if (shippingProvider === 3) {
    shippingProviderName = '日本郵便 ';
  }

  const isText = (text) => {
    return text ? text : '-';
  };

  return (
    <dl className="a__saleinfo_list">
      <dt>出品者</dt>
      <dd className="seller_name">
        {id === sellerUserId ? (
          <Link to={paths.mypage.all}>{replaceTofu(sellerName)}</Link>
        ) : (
          <Link to={`/users/${sellerUserId}`}>{replaceTofu(sellerName)}</Link>
        )}
      </dd>

      <dt>出品日時</dt>
      <dd>{postedAt}</dd>

      <dt>サイズ（JA規格）</dt>
      <dd className="size">
        {/* <p>{Number(size) < 1 ? '-' : `${getLocaleStringLocales(size)} cm`}</p> */}
        <p>{isText(sizeText)}</p>
      </dd>

      <dt>重量</dt>
      <dd className="weight">
        <p>
          {Number(weight) < 1 ? '-' : `${getLocaleStringLocales(weight)} g`}
          <br />
          {weightAdditionalInfo}
        </p>
      </dd>

      {/* <dt>釣り場（産地）</dt>
      <dd>
        <p>
          {fishingSpot || '-'}
          <br />
          {fishingSpotDetail || ''}
        </p>
      </dd>

      <dt>釣り上げた日（水揚げ日）</dt>
      <dd>{fishingDate}</dd>

      <dt>分類</dt>
      <dd>{isNature ? '天然' : '養殖'}</dd>

      <dt>締め方</dt>
      <dd>
        {methodText || '-'}
        {isIkejime ? (
          <>
            <br />
            活〆
          </>
        ) : (
          ''
        )}
      </dd>

      <dt>処理方法</dt>
      <dd>{cleaningText || '-'}</dd>

      <dt>処理に使用した水</dt>
      <dd>{waterText || '-'}</dd>

      <dt>締め処理を行ったタイミング</dt>
      <dd>{ikejimeTimingText || '-'}</dd>

      <dt className={isVacuum ? 'w__save' : null}>保管方法</dt>
      <dd>
        <p>
          {saveText || '-'}
          {isVacuum ? (
            <>
              <br />
              真空パック保管
            </>
          ) : (
            ''
          )}
        </p>
      </dd> */}

      {/* 追加 */}
      <dt>生産者</dt>
      <dd>{isText(producer)}</dd>
      <dt>品種名</dt>
      <dd>{isText(breed)}</dd>
      <dt>等級</dt>
      <dd>{isText(gradeText)}</dd>
      <dt>産地</dt>
      <dd>
        {isText(originText)}
        <br />
        {originAdditionalInfo}
      </dd>
      <dt>平均糖度</dt>
      <dd>
        {isText(averageSugarContent)}
        {averageSugarContent ? '%' : ''}
      </dd>
      <dt>収穫日</dt>
      <dd>{isText(harvestDate === 'Invalid date' ? '' : harvestDate)}</dd>
      <dt>収穫のタイミング</dt>
      <dd>{isText(harvestTimingText)}</dd>
      <dt>出品者側保管方法</dt>
      <dd>{isText(saveMethodSellerText)}</dd>
      <dt>ご家庭での推奨保管方法</dt>
      <dd>
        {isText(saveMethodUserText)}
        <br />
        {saveMethodUserAdditionalInfo}
      </dd>
      <dt>栽培方法</dt>
      <dd>{isText(cultivationMethodText)}</dd>
      <dt>土壌</dt>
      <dd>
        {isText(soilText)}
        <br />
        {soilAdditionalInfoTexts
          ? soilAdditionalInfoTexts.map((e, i) => `${i !== 0 ? '、' : ''}${e}`)
          : '-'}
      </dd>
      <dt>農薬</dt>
      <dd>
        {isText(pesticidesText)}
        <br />
        {pesticidesAdditionalInfoText && pesticidesAdditionalInfoText}
      </dd>
      <dt>遺伝子組み換え</dt>
      <dd>{isText(geneticText)}</dd>
      <dt>有機JAS認定</dt>
      <dd>{isText(jasText)}</dd>
      <dt>個別的義務表示事項</dt>
      <dd>{isText(individualObligations)}</dd>
      <dt>グローバルGAP</dt>
      <dd>{isText(globalGap)}</dd>

      <dt>配送料の負担</dt>
      <dd>
        {expenseText}
        {shippingProviderName ? (
          <>
            <br />
            {shippingProviderName}
          </>
        ) : null}
      </dd>

      <dt>配送方法</dt>
      <dd>{deliveryText || '-'}</dd>

      {window.isAuthed ? (
        <>
          {paymentMethod(expenseText) && !isSeller(sellerUserId, id) ? (
            <>
              <dt>
                クール便 <br />
                配送料目安
              </dt>
              {getDeliveryPrices(area, prefecture, size) ? (
                <dd>
                  約{getDeliveryPrices(area, prefecture, size)}円 <br />
                  ※条件によって異なる場合がございます
                </dd>
              ) : (
                <dd>
                  配送情報の取得に失敗したため、金額の表示に失敗しました。
                </dd>
              )}
            </>
          ) : null}
        </>
      ) : null}

      <dt>発送までの日数</dt>
      <dd>{timingText}</dd>

      <dt>発送元の地域</dt>
      <dd>{area}</dd>

      <dt>即決価格</dt>
      <dd>
        {fixPrice >= 2147483647 ? '-' : `¥${getLocaleStringLocales(fixPrice)}`}
      </dd>
      <dt>配送サイズ区分</dt>
      <dd>{shippingExpense === 3 ? shippingSize : '-'}</dd>
    </dl>
  );
}

export default enhance((props) => <SaleInfoList {...props} />);

import * as React from 'react';
import { Button, Header } from 'semantic-ui-react';
import enhance from './enhancer';

function SignUpThank(props) {
  return (
    <div className="a__signup_thank">
      <div className="wrapper">
        <div className="content">
          <Header as="h1" className="title_text">
            登録が完了しました！
          </Header>
          <div className="content">
            <p className="container_text">
              新規会員のご登録ありがとうございます。
            </p>
          </div>

          <Button className="submit_login" onClick={props.handleOnIsAuth}>
            <div>
              <span className="content">Fruit Saleをはじめましょう！</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <SignUpThank {...props} />);

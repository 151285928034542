import React from 'react';
import { paths } from '@/config';
import './style.scss';
import {
  closedMessage,
  getTimeType,
  shippingMessage,
} from '../Card/getTimeType';
import { replaceTofu } from '@/utils/string';
import CardLabel from '../Card/CardLabel';
import CommentLabel from '../Card/CommentLabel';

interface DataType {
  id: number;
  imageURL: string;
  fishKind: string;
  sellerName: string;
  sellerCompany: string;
  isCompany: boolean;
  fixPrice: string;
  displayPrice: string;
  bidCount: number;
  fixed: boolean;
  closeBidAtTemp: string;
  fishingSpot: string;
  weight: number;
  isNature?: boolean;
  shippingExpense: number;
  bid: boolean;
  closed: boolean;
  closedReason: number;
  commentAdd: boolean;
  imageAdd: boolean;
  sellerUserId: number;
  area: string;
  closeBidAt: string;
  size: string;
}

type Props = {
  data: DataType;
};

export const ListItem = ({ data }: Props) => {
  const {
    id,
    imageURL,
    fishKind,
    isCompany,
    sellerName,
    sellerCompany,
    fixPrice,
    displayPrice,
    bidCount,
    fixed,
    closeBidAtTemp,
    fishingSpot,
    weight,
    isNature,
    shippingExpense,
    bid,
    closed,
    closedReason,
    commentAdd,
    imageAdd,
    sellerUserId,
    area,
    closeBidAt,
    size,
  } = data;
  const env = process.env.REACT_APP_ENV_CODE;
  const thumbnailImageUrl = imageURL.replace(env, `${env}-thumbnail`);

  const { diff, type } = getTimeType(closeBidAtTemp, fixed);
  const { closeMessageLabel, closeMessageValue } = closedMessage(
    type,
    diff,
    closeBidAtTemp
  );
  const shippingMessageLabel = shippingMessage(shippingExpense);

  return (
    <div className="list-item">
      <div className="list-item__image">
        <a
          href={`${paths.fishes.root}/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardLabel
            bid={bid}
            closed={closed}
            fixed={fixed}
            closedReason={closedReason}
          />

          <CommentLabel commentAdd={commentAdd} imageAdd={imageAdd} />

          <img
            src={thumbnailImageUrl}
            alt=""
            onError={({ target }) => {
              // thumbnailの取得に失敗した時は、元イメージを取得する
              (target as HTMLImageElement).src = imageURL;
            }}
            loading="lazy"
            decoding="async"
          />
        </a>
      </div>
      <div className="list-item__body">
        <h3 className="list-item__title">
          <a
            href={`${paths.fishes.root}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fishKind}
          </a>
        </h3>
        <div className="list-item__label">
          {isNature && <p>天然</p>}
          {shippingMessageLabel === '送料無料' && <p>{shippingMessageLabel}</p>}
        </div>
        <div className="list-item__info">
          <p>
            産地<span>{fishingSpot ? `${fishingSpot}産` : '未選択'}</span>
          </p>
          <p>
            重量<span>{weight ? `${weight}g` : '-'}</span>
          </p>
          <p>
            サイズ（JA規格）<span>{size ? size : '-'}</span>
          </p>
        </div>
        <div className="list-item__price">
          <p>
            現在価格
            <span className="-red">
              {parseInt(displayPrice).toLocaleString()}円
            </span>
          </p>
          <p>
            即決価格
            <span>
              {fixPrice !== '-'
                ? `${parseInt(fixPrice).toLocaleString()}円`
                : fixPrice}
            </span>
          </p>
        </div>
        <div className="list-item__other-info">
          <p>
            入札
            <span className="">
              <a
                href={`${paths.fishes.root}/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {bidCount}
              </a>
            </span>
          </p>
          <p>
            {closeMessageLabel}時間
            <span>
              {!closed ? closeMessageValue : closeBidAt}
              {!closed && <span>{closeBidAt}終了</span>}
            </span>
          </p>
        </div>
      </div>
      <div className="list-item__side">
        <div className="list-item__seller">
          <p>
            出品者
            <span>
              <a href={`/users/${sellerUserId}`}>
                {isCompany ? sellerCompany : replaceTofu(sellerName)}
              </a>
            </span>
          </p>
          <p>
            発送元地域
            <span>{area}</span>
          </p>
          <p>
            配送料の負担
            <span>{shippingMessageLabel}</span>
          </p>
        </div>
        {isCompany && (
          <div className="list-item__label">
            <p>業者出品</p>
          </div>
        )}
      </div>
    </div>
  );
};

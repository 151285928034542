export default [
  {
    title: '第1条（目的）',
    body: `本サービスは、出品商品（鮮商品、以下、「商品」といいます。）に関して、インターネットを利用したオークション方式によるユーザー（次条に定めます。）間の取引の機会を提供するものです。ユーザー間の商品の販売または提供にかかる契約は、取引条件に関する双方の意思が合致したときに成立します。ユーザー間で商品の販売または提供に関する取引条件をよく確認し、取引を行うかどうかの判断はユーザー自身で行ってください。`,
    key: 'termsContens1',
  },
  {
    title: '第2条（定義）',
    body: `本利用規約において、以下の用語は、別途定義されている場合及び文脈上他の意味と解すべき場合を除き、以下の意味を有するものとし、本利用規約のほか、プライバシーポリシー及びご利用ガイドにおいても適用されるものとします。<br/>
      ①「ユーザー」とは、当社が登録承認しアカウントを付与した、日本国内において本サービスを利用する日本在住の個人または法人をいいます。<br/>
      ②「出品」とは、ユーザーが本サービスにて、商品の取引に必要なコンテンツを掲載・発信し、他のユーザーが閲覧可能かつユーザーが商品を取引できる状態にすることをいいます。<br/>
      ③「出品者」とは、個人及び事業者を問わず本サービスを通じて商品を出品するユーザーをいいます。<br/>
      ④「入札者」とは、本サービスにて掲載・発信された商品に入札したユーザーをいいます。<br/>
      ⑤「落札者」とは、本サービスにて商品を落札したユーザーをいいます。<br/>
      ⑥「コンテンツ」とは、当社またはユーザーが本サービスに掲載・発信した情報をいいます。<br/>
      ⑦「個人情報」とは、個人情報の保護に関する法律（平成１５年法律第５７号。その後の改正を含みます。）第２条１項に定める「個人情報」を指すものとし、ユーザーが入力した氏名、会社名、拠点名、郵便番号、住所、生年月日、性別、職業、電話番号、アカウント情報（電子メールアドレス、パスワード等を含みます。）、クレジットカード情報、利用履歴等で、かつ特定の個人を識別できる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含みます。）をいいます。<br/>
      ⑧「プライバシーポリシー」とは、当社が定めるプライバシーポリシーをいいます。<br/>
      ⑨「ご利用ガイド」とは、当社が定める本サービスに関するガイドをいいます。<br/>
      ⑩「本規約」とは、本利用規約、プライバシーポリシー及びご利用ガイドをいい、これらはすべて本規約を構成するものとします。<br/>
      ⑪「利用料」とは、本サービスの利用に関連して、当社がユーザーから徴収する料金（販売手数料その他名称の如何を問わないものとします。）をいいます。`,
    key: 'termsContens2',
  },
  {
    title: '第3条（利用規約の適用）',
    body: `本規約は、本サービスを利用する出品者及び落札者と当社との権利義務関係を定めるものであり、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。ユーザーは、本規約の定めに従ってサービスを利用しなければならず、本規約に同意しない限り本サービスを利用できません。ユーザーは、本サービスを利用することにより本規約に同意したものとみなされます。`,
    key: 'termsContens3',
  },
  {
    title: '第4条（利用規約の改定）',
    body: `１ 当社は、本規約を改定または変更する場合があります。当社が本規約を変更し、本サービスを提供するウェブサイト上で当該変更した旨を通知し、当該通知以降に本サービスを利用した場合、当該利用規約の変更を承諾したものとみなします。<br/>
      ２ 当社は、本規約の改定または変更によりユーザーに生じたすべての損害について、当社の故意または重過失に起因する場合を除き、一切の責任を負いません。
      `,
    key: 'termsContens4',
  },
  {
    title: '第5条（未成年者の利用制限）',
    body: `１ 本サービスを利用する個人または法人の代表者は、成人に限ります。未成年者は法定代理人の同意がある場合その他いかなる場合であっても本サービスを利用することは出来ません。<br/>
      ２ 未成年者が本サービスを利用し、ユーザーまたは第三者に損害が生じた場合には、当社は一切責任を負いません。<br/>
      ３ 未成年者であることを偽って本サービスを利用し、または取引した場合、未成年者であることを理由とした各行為の取消しはできません。`,
    key: 'termsContens5',
  },
  {
    title: '第6条（利用登録）',
    body: `１ 本サービスの利用登録は、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、完了するものとします。<br/>
    ２ ユーザー及びユーザー登録をしようとする者（以下、「ユーザー等」といいます。）は、複数のユーザー登録を行うことができないものとします。<br/>
    ３ 当社は、ユーザー登録をしようとする者が以下の各号のいずれかに該当する場合、ユーザー登録の申請を承認しないことがあります。<br/>
      ① 第５条により本サービスの利用を制限する場合<br/>
      ② 過去に本規約違反等により、当社から利用停止等の処分を受けている場合<br/>
      ③ 登録内容に正確ではない情報または虚偽の情報が含まれている場合<br/>
      ④ 当社の運営、サービス提供もしくは他のユーザーの利用を妨害するまたはそれらに支障をきたす行為を行った場合やそのおそれがあると当社が合理的な理由に基づき判断した場合<br/>
      ⑤ 暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロもしくは特殊知能暴力集団、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）、テロリスト等日本政府もしくは外国政府が経済制裁の対象として指定する者に該当すること、または暴力団員等と一定の関係を有する者（暴力団員等に対して資金を提供しもしくは便宜を供与するなどの関与をしていると認められること、暴力団員等を不当に利用していると認められること、または、ユーザー登録をしようとする者が法人の場合、暴力団員等がその経営を支配しもしくはその法人の経営に実質的に関与していると認められること、その他暴力団員等と社会的に非難されるべき関係を有していると認められること）（以下、上記のすべてを総称して「反社会的勢力」といいます。）である場合<br/>
      ⑥ その他当社が不適当であると合理的な理由に基づき判断する場合<br/>
      ４ ユーザー等は、反社会的勢力のいずれにも該当しないこと、かつ将来にわたっても該当しないこと、及び、自らまたは第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をしまたは暴力を用いる行為、風説を流布し、偽計を用いまたは威力を用いて当社の信用を毀損しまたは当社の業務を妨害する行為、その他これらに準ずる行為を行わないことを確約するものとします。`,
    key: 'termsContens6',
  },
  {
    title: '第7条（登録情報の保証）',
    body: `１ ユーザー登録は、必ず本人が、本サービスの利用に際して必要となる情報を自らの責任で正確に登録するものとします。当社は、ユーザーが登録した情報の正確性、真実性、信用性について一切保証しません。<br/>
      ２ 登録した情報に誤りがあった場合または変更が生じた場合、ユーザーは、所定の手続に従い自己の責任において、速やかに登録した情報を修正または変更するものとします。登録内容に変更があったにも関わらず変更を行っていない場合、当社は、登録内容に変更がないものとして取り扱うことができます。変更の届出があった場合でも、変更登録前に行われた取引や各種手続は、変更前の情報に依拠する場合があります。<br/>
      ３ ユーザーが、ユーザー登録や登録内容の変更をしたことや、当社がユーザー登録を承認しないことにより生じた損害に関して、当社は一切の責任を負わないものとします。`,
    key: 'termsContens7',
  },
  {
    title: '第8条（登録情報の管理）',
    body: `１ ユーザーを特定する所定の認証方法（ＩＤとパスワードの組み合わせや携帯電話事業者から送信される携帯電話番号ごとに一意に付与される符号の登録情報との一致確認による認証を含みますが、これらに限ません。）によりログインされた場合、当社は当該ユーザーご自身による利用とみなします。ユーザーを特定する所定の認証方法でログインがなされ、サービスの利用や商品の購入などによって費用が発生した場合、当該ユーザーが負担するものとします。<br/>
      ２ ユーザーは、入力したメールアドレス、パスワード等の情報（以下「アカウント情報」といいます。）を自ら管理する責任を負います。ユーザーは、アカウント情報を第三者に利用させることや、譲渡、売買、質入、貸与、賃貸その他形態を問わず処分することはできません。<br/>
      ３ アカウント情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任はユーザーが負うものとし、当社は一切責任を負わないものとします。また、アカウント情報が不正に利用されたことにより当社に損害が生じた場合、ユーザーは当該損害を賠償するものとします。<br/>
      ４ ユーザーは、アカウント情報が第三者に漏えいした場合またはそのおそれがある場合、速やかに当社まで連絡するものとします。また、その際に当社の指示がある場合にはこれに従うものとします。`,
    key: 'termsContens8',
  },
  {
    title: '第9条（本サービスの利用等）',
    body: `１ ユーザーは、本規約の内容を遵守し、本サービスを利用するものとします。<br/>
      ２ 本サービスを利用するにあたり必要な情報端末、ソフトウェア、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとします。<br/>
      ３ 前項の通信環境等に起因して本サービスの利用に支障が発生した場合及びそれらがもたらす諸影響に関して、当社は一切責任を負いません。<br/>
      ４ ユーザーが、本サービスやそれらを構成するデータを、本サービスの提供目的の範囲を超えて利用した場合、当社は、それらの行為を差し止める権利及びそれらの行為によってユーザーが得た利益相当額または当社が被った損害を請求する権利を保有するものとします。`,
    key: 'termsContens9',
  },
  {
    title: '第10条（利用料）',
    body: `本サービスを利用するにあたり、以下の利用料が必要となります（１円未満の端数金額が生じた場合切り上げとなります。）。利用料の詳細については、本サービスのホームページをご確認ください。但し、キャンペーン等当社が別途利用料を定めた場合には、当該キャンペーン等の定めに従うものとします。<br/>
    １ 出品者<br/>
    出品者には以下の利用料が発生します。なお、出品者は、下記いずれの利用料について、一度発生した利用料はいかなる場合においても支払を免れません。<br/>
    ① 落札システム利用料<br/>
    商品等の落札が完了した時点でお支払いいただく義務が発生します。<br/>
    ② 出品取消システム利用料<br/>
    成立した取引を取り消すと、お支払いいただく義務が発生します。<br/>
    ２ 入札者及び落札者<br/>
    利用料は発生しません。
    `,
    key: 'termsContens10',
  },
  {
    title: '第11条（ユーザー間の取引及びキャンセル等）',
    body: `１ 本サービスの利用を契機としてユーザー間で契約が成立した場合には、取引条件に従い互いに誠実に義務を履行しなければなりません。<br />
    ２ 当社は、ユーザー間の取引及び商品等の内容には一切関与しません。本サービスの利用を契機として成立したユーザー間の売買契約、出品等の保証等に関しては、すべて当事者であるユーザー間の自己責任とします。なお、一旦入札したあとのキャンセル、返品、返金又は交換（以下、「キャンセル等」といいます。）は、理由の有無・内容を問わず受け付けておりません。入札しようとするユーザーは自らの責任で入札する必要があり、当社にキャンセル等を求めることはできません。また出品者が落札後にキャンセル等をする場合、事務手数料として落札価格の４％を申し受けます。<br />
    ３ ユーザーは、最高価格落札者が落札後、最高価格落札者の決済用登録カードの不具合その他の事情によって決済されなかった場合、次点入札者が落札することを理解したうえで入札します。<br />
    ４ ユーザー間又はユーザーと第三者との間で、本サービスに関連して、裁判、クレーム、請求等のトラブルを含む紛争（以下、「対外紛争」といいます。）が生じた場合、ユーザー各自の責任及び費用で解決するものとし、当社は、当該紛争に一切関与しません。`,
    key: 'termsContens11',
  },
  {
    title: '第12条（出品者の責務）',
    body: `１ 出品者は、出品する商品、出品方法、商品の引渡方法及び保管方法等にかかる関係法令及び監督官庁のガイドラインを遵守しなければなりません。<br/>
      ２ 出品者は、出品における当社所定のルールを遵守しなければなりません。出品者の禁止行為、出品禁止物、出品のルール等については、ご利用ガイド（ご利用ガイド記載のリンク等も含みます）その他当社が規定したルールを遵守しなければなりません。`,
    key: 'termsContens12',
  },
  {
    title: '第13条（取引に対する当社の責任）',
    body: `１ 当社は、ユーザー間の取引及び商品等に関して契約当事者としての責任、権利及び権限は一切有さず、当該契約について一切の責任を負いません。出品者及び落札者はこれに予め同意したものとみなします。<br/>
      ２ 当社は、本サービスに出品される商品等の品質、安全性または適法性について調査及び判断する義務を負わず、また一切保証しません。<br/>
      ３ 当社は、本サービスに出品された商品等の説明内容の信頼性もしくはその精度、出品者が提示する商品等を実際に販売または提供できるかどうか、または落札者が落札した商品等の代金の支払能力があるか否かに関しても一切保証しません。<br/>
      ４ 当社は、取引の相手方の情報をユーザーに開示する義務を負いません。<br/>
      ５ ユーザーは、当該ユーザーが行った取引に関し、対外紛争の対応のために当社に生じた弁護士費用を含むあらゆる費用を負担し、また賠償金等については当社と連帯して賠償するものとします。`,
    key: 'termsContens13',
  },
  {
    title: '第14条（取引に関する措置）',
    body: `１ 当社は、本規約に定める事項もしくは本規約の趣旨に違反する行為が行われたと判断した場合、または他のユーザー等第三者の権利を侵害したもしくは侵害するおそれがあると判断した場合には、事前にユーザーに通知することなく、直ちに該当する商品の出品の拒絶、編集、移動、削除等を当社自身の判断により実施する権利を保有するものとします。<br/>
      ２ 当社は、前項の措置をとる場合、その理由を説明する義務を負わないものとします。<br/>
      ３ 当社は、ユーザーの出品回数に関し一定の制限を設けることがあります。<br/>
      ４ 当社は、本条の措置により生じる損害について、一切の責任を負わないものとします。`,
    key: 'termsContens14',
  },
  {
    title: '第15条（サービス利用に関する禁止事項）',
    body: `１ 本サービスの利用に際しては、以下に定める行為を禁止します。<br/>
      ① 法令または本規約に違反する行為<br/>
      ② 当社のサービス運営を妨げる行為、または、そのおそれのある行為<br/>
      ③ 他のユーザーのＩＤ等を使用して本サービスを利用する行為<br/>
      ④ 当社、他のユーザーまたは第三者の利益を侵害する行為、または、そのおそれのある行為<br/>
      ⑤ 当社、他のユーザーもしくは第三者の著作権その他の権利を侵害する行為、または、そのおそれのある行為<br/>
      ⑥ 虚偽の情報を投稿、掲載、開示、提供または送信（以下、これらを総称して「投稿等」といいます）する行為<br/>
      ⑦ 社会規範・公序良俗に反するものを投稿等する行為、または、そのおそれのある行為<br/>
      ⑧ わいせつ的、暴力的その他閲覧者を不快にさせる画像その他の表現の投稿等をする行為<br/>
      ⑨ 他のユーザーの迷惑となるような行為、または、そのおそれのある行為<br/>
      ⑩ 他のユーザーのプライバシーを侵害し、名誉を毀損し、または精神的損害を与える行為、または、そのおそれのある行為<br/>
      ⑪ 犯罪に結びつくおそれのある行為<br/>
      ⑫ 選挙運動に関連する行為<br/>
      ⑬ 民族、人種、性別、年齢等による差別につながる表現の投稿等をする行為、または、そのおそれのある行為<br/>
      ⑭ 他のユーザーの使用するソフトウェア、ハードウェアなどの機能を破壊したり、妨害したりするようなプログラムなどの投稿等をする行為、または、そのおそれのある行為<br/>
      ⑮ 当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為、または、そのおそれのある行為<br/>
      ⑯ 本サービス、配信する広告、または、サイト上で提供されている広告等を妨害する行為、または、そのおそれのある行為<br/>
      ⑰ 商品等にかかる取引に必要な範囲を超えた、他のユーザーの個人情報や他のユーザーが投稿したコンテンツ等の収集及び利用行為（ここでいう「個人情報」は、第２条１１項に定める個人情報のほか、データ、文章、写真、図画、絵、評価等、本サービスに関連してユーザーが掲載、送信した全てのものを指します。）。<br/>
      ⑱ 本サービスの提供の趣旨に照らして本来のサービス提供の目的とは異なる目的で利用する行為<br/>
      ⑲ 手段の如何を問わず他人からＩＤやパスワードを入手したり、他人にＩＤやパスワードを開示したり提供したりする行為<br/>
      ⑳ 本サービスに関連して反社会的勢力に直接・間接に利益を提供する行為、または、そのおそれのある行為<br/>
      ㉑ その他、当社が本サービスのユーザーとして適切ではないと判断する行為<br/>
      ２ 前項の規定に違反した場合またはその疑いがある場合には、当社は、当該ユーザーに対して、第１４条または第１６条に規定する措置を講じることがあります。
      `,
    key: 'termsContens15',
  },
  {
    title: '第16条（本サービスの利用停止等）',
    body: `１ 当社は、ユーザーが以下の各号のいずれかに該当した場合または該当したと当社が合理的な理由に基づき判断した場合、事前の通知なしに、ユーザーＩＤの削除、本サービスの全部もしくは一部へのアクセスの拒否、利用停止等、または、ユーザーに関連するコンテンツや情報の全部もしくは一部の削除等当社が必要と認める措置をとることができるものとします。なお、当社は、ユーザーが以下の各号のいずれにも該当しないことを確認するために、当社が必要と判断する本人確認を行うことができ、かかる本人確認が完了するまで本サービスの全部または一部へのアクセスの拒否、利用停止等の措置をとることができます。<br/>
      ① 法令または本規約に違反した場合<br/>
      ② 不正行為があった場合<br/>
      ③ サービスの利用料の支払がなされない場合<br/>
      ④ 登録した情報が虚偽の情報であると当社が判断した場合<br/>
      ⑤ 本規約上必要となる手続または当社への連絡を行わなかった場合<br/>
      ⑥ 登録した情報が既存の登録と重複している場合<br/>
      ⑦ 登録した携帯電話番号またはメールアドレスが不通になったことが判明した場合<br/>
      ⑧ ユーザーが債務超過、無資力、支払停止または支払不能の状態に陥った場合<br/>
      ⑨ 他のユーザーや第三者に不当に迷惑をかけた場合<br/>
      ⑩ ユーザーの行為が本サービスの提供に支障を来たすものと当社が判断した場合<br/>
      ⑪ 一定期間に渡ってＩＤが使用されない場合<br/>
      ⑫ 第６条第３項各号のいずれかに該当する場合<br/>
      ⑬ 第１５条各号のいずれかに該当する場合<br/>
      ⑭ ユーザーが自らまたは第三者をして、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動もしくは暴力を用いる行為、または風評を流布し、偽計を用いもしくは威力を用いて、信用を毀損もしくは業務を妨害する行為をした場合<br/>
      ⑮ 本サービスを、提供の趣旨に照らして本来のサービス提供の目的とは異なる目的で利用する行為<br/>
      ⑯ その他当社がユーザーに相応しくないと判断した場合<br/>
      ２ 当社は、第１項の措置を受けたユーザーに対し、将来にわたって当社が提供するサービスの利用及びアクセスを禁止することができるものとします。<br/>
      ３ 当社は、第１項の措置をとる場合、その理由を説明する義務を負わないものとします。<br/>
      ４ 当社は、本条の措置により生じる損害について、一切の責任を負わないものとします。
      `,
    key: 'termsContens16',
  },
  {
    title: '第17条（本サービスの利用終了）',
    body: `１ ユーザーは、アカウントの削除その他当社所定の方法により、いつでも本サービスの利用を終了することができます。ユーザーは、本サービス終了時点から本サービスを利用することができなくなります。ただし、取引の決済や商品の郵送等の取引の手続が未完のものがある場合は退会することができず、ユーザーは、一連の未完の取引を本規約に従って遅滞なく円滑に進め、完了させた後、当社に退会の申し出を行わなければなりません。<br/>
      ２ 前項に基づき本サービスの利用を終了した場合、誤ってアカウントを削除した場合、前条に基づき利用停止等の措置が取られた場合、その他理由の如何を問わず、ユーザーが本サービスを利用する権利を失った場合には、ユーザーは、本サービスの他、登録情報その他ユーザーが本サービスを利用することにより当社が得たすべての情報（以下、「蓄積情報」といいます。）も利用することができなくなることをあらかじめ承諾するものとします。<br/>
      ３ 当社は、ユーザーが本サービスを利用することができなくなったことにより、ユーザー及び第三者に生じる損害について、一切の責任を負わないものとします。`,
    key: 'termsContens17',
  },
  {
    title: '第18条（本サービス内容の保証）',
    body: `１ 当社は、提供する本サービスの内容について、瑕疵やバグがないこと及びコンピュータウィルス等有害なものが含まれていないことは保証しておりません。<br/>
      ２ ユーザーが書き込んだURL等のリンク先のウェブサイト等において生じた損害に関して、当社は一切責任を負わないものとします。`,
    key: 'termsContens18',
  },
  {
    title: '第19条（本サービス内容の変更・中断・終了等）',
    body: `１ 当社は、ユーザーへの事前通知をすることなく、いつでも本サービスの内容の全部または一部を変更、追加、または終了することができるものとします。<br/>
    ２ 当社は、以下の各号の事由が生じた場合には、ユーザーに事前に通知することなく、本サービスの全部または一部を一時的に停止または中断することがあり、ユーザーはこれを予め承諾するものとします。<br/>
    ① サーバー、通信回線、その他の設備の故障、障害の発生、またはその他の理由により本サービスの提供が出来なくなった場合<br/>
    ② 定期的または緊急のメンテナンスその他システム保守、点検、修理、変更を行う場合<br/>
    ③ 停電、騒乱、火災もしくは天災地変等の不可抗力によって本サービスの提供が困難となった場合<br/>
    ④ 法令もしくはこれに基づく措置により本サービスの提供が困難となった場合<br/>
    ⑤ その他前各号に準じ当社が必要と判断した場合<br/>
    ３ 当社は、本条に基づき当社が行った措置等によりユーザー及び第三者に生じる損害について、一切責任を負わないものとします。
    `,
    key: 'termsContens19',
  },
  {
    title: '第20条（データの取扱い）',
    body: `ユーザーが当社の管理しているサーバーに保存しているデータは当社に帰属するものであり、当社はユーザーに対して保管義務やバックアップ義務を負担しません。当該情報が必要な場合、ユーザー自身の責任でバックアップを行ってください。`,
    key: 'termsContens20',
  },
  {
    title: '第21条（個人情報等の取扱い）',
    body: `１ 当社は、本規約（プライバシーポリシーを含みます。）に従って個人情報等を取扱います。<br/>
    ２ ユーザーは、本サービスの利用の前に、本サービス上で、プライバシーポリシーを必ず確認し、その内容に同意した上で、本サービスを利用するものとします。ユーザーは本サービスを利用することによりプライバシーポリシーに同意したものとみなします。<br/>
    ３ ユーザーは、本サービスを通じて得た個人情報等に関し、本サービスの提供目的の範囲内においてのみ利用することができ、同目的の範囲を超えて利用することはできないものとします。`,
    key: 'termsContens21',
  },
  {
    title: '第22条（知的財産権）',
    body: `１ 本サービスに関連するコンテンツその他すべての素材に関する著作権その他の一切の権利は当社または当該権利を有する第三者に帰属します。<br/>
    ２ 当社は、本サービスの宣伝、運営、及び発表等を目的として、ユーザーが掲載した写真など本サービスに関連するコンテンツその他すべての素材を、当社及び当社の指定する者が自由に利用できるものとします。<br/>
    ３ すべてのユーザーは本サービスに関連するコンテンツその他すべての素材につき、権利者の許可なく、複製、翻訳、頒布、譲渡、二次利用その他一切の知的財産権の行使をすることはできません。なお、本規約に基づく本サービスの利用の許諾は、本サービスに関する当社または当該権利を有する第三者の権利の使用許諾を意味するものではありません。<br/>
    ４ ユーザーが本サービス利用に関して投稿等をしたコンテンツに関する一切の責任は、当該ユーザーが負うものとします。当社は、これらに関して、一切保証せず、一切の責任を負いません。`,
    key: 'termsContens22',
  },
  {
    title: '第23条（当社に対する補償）',
    body: `ユーザーの行為が原因で生じたクレームなどに関連して当社に費用が発生した場合または当社が賠償金などの支払いを行った場合、当該ユーザーは当社が支払った費用や賠償金など（名目の如何を問わず、相当な弁護士費用を含みます。）を負担しなければなりません。`,
    key: 'termsContens23',
  },
  {
    title: '第24条（損害賠償責任）',
    body: `１ 当社は、ユーザーが本サービスの利用より被った損害につき、賠償する責任を一切負わないものとします。<br/>
    ２ ユーザーとの本規約に基づく本サービスの利用に関する契約が消費者契約法に定める消費者契約に該当する場合、前項の規定は適用されないものとします。なお、消費者契約に該当し当社が損害賠償責任を負う場合においても、当社は、通常生じる損害の範囲内で、かつ、当該ユーザーの利用料１か月相当分を上限として賠償責任を負担するものとします。`,
    key: 'termsContens24',
  },
  {
    title: '第25条（広告掲載）',
    body: `当社は、本サービスに関連して、当社または当社に掲載依頼した第三者の広告を掲載することができるものとします。`,
    key: 'termsContens25',
  },
  {
    title: '第26条（他のサービスへの遷移）',
    body: `１ ユーザーが本サービスを利用するにあたり、本サービスから、第三者が運営する他のサービス（以下、「外部サービス」といいます。）に遷移する場合があります。ユーザーは、予めこれに同意するものとし、本規約及び外部サービスの利用規約等を遵守して、本サービス及び外部サービスを利用するものとします。<br/>
    ２ 外部サービスへの遷移後に本サービスの利用を継続したユーザーは、外部サービスの利用規約等に同意したものとみなします。当社は、外部サービスについて何らの保証も行わないものとし、ユーザー及び第三者が外部サービスを利用することにより生じる損害について、一切責任を負わないものとします。`,
    key: 'termsContens26',
  },
  {
    title: '第27条（通知または連絡）',
    body: `１ 当社から、個別のユーザーに対する通知は、ユーザーが当社に登録した連絡先に情報を送信した時点でユーザーに対する通知が到達したものとします。<br/>
    ２ 当社は本サービスに関して必要な情報を公表する場合、本サービスを提供するウェブサイトの所定場所に掲載することによって行うものとし、当該方法によって公表された情報を知らないことによってユーザーが損失などを被ったとしても当社は責任を負いません。<br/>
    ３ ユーザーから当社に対する通知、問い合わせは、当社が指定する方法によって行うものとし、その他の方法で通知、問い合わせがなされたとしても、当社は対応を約束するものではありません。`,
    key: 'termsContens27',
  },
  {
    title: '第28条（権利義務の譲渡禁止）',
    body: `ユーザーは、本サービスに関連して生じる一切の権利義務関係及び契約上の地位の全部または一部を譲渡、貸与、贈与、担保提供、その他一切の処分をしてはなりません。`,
    key: 'termsContens28',
  },
  {
    title: '第29条（事業譲渡）',
    body: `当社が本サービスに係る事業を第三者に譲渡する場合（事業譲渡、会社分割その他本サービスの主体が移転する一切の場合を含みます。）には、当社は、当該事業の譲渡に伴い、ユーザーの本規約に基づく契約上の地位、本規約に基づく権利義務及びユーザー登録に伴い登録された情報その他の情報を当該事業の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき予め承諾するものとします。`,
    key: 'termsContens29',
  },
  {
    title: '第30条（誠実協議）',
    body: `本規約に定めのない事項または本規約の解釈に疑義が生じた場合には、ユーザーは、当社の定めるところに従うものとします。これにより解決しない場合には、当社及びユーザーは、誠実に協議を行い、速やかに解決を図るものとします。`,
    key: 'termsContens30',
  },
  {
    title: '第31条（準拠法、裁判管轄）',
    body: `１ 本利用規約の成立、効力発生、解釈にあたっては日本法を準拠法とします。<br/>
    ２ 本サービスに関連して生じた紛争については、その内容に応じて大阪簡易裁判所または大阪地方裁判所を第一審の専属的合意管轄裁判所とします。`,
    key: 'termsContens31',
  },
  {
    title: '第32条（適用制限）',
    body: `本規約の規定がユーザーとの間で適用される法令に違反した場合、当該違反した条項に限り、無効として、適用しないものとします。この場合、他のユーザーとの関係において当該規定は無効となるものではなく、また、他の規定の有効性に影響を及ぼさないものとします。`,
    key: 'termsContens32',
  },
];

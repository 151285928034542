import { Auth } from '@/utils';
import notify from '@/migration/notification';
import { connect } from 'react-redux';
import {
  compose,
  setDisplayName,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { reduxForm } from 'redux-form/immutable';
import { appAction, usersAction } from '@/actions';
import { signUp as validate } from '@/validates';
import { storeUser } from './utils';
import { GA } from '@/utils';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  app: state.app.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, ...appAction, push, notify }, dispatch);

const enhance = compose(
  setDisplayName('SignUp'),
  reduxForm({
    form: 'signUp',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'updateLoading', false),
  withState('recapchaResult', 'updateRecapchaResult', undefined),
  withState('displayRecapchaError', 'updateDisplayRecapchaError', false),
  withHandlers({
    submitFederation: () => async (event, identityProvider) => {
      try {
        event.preventDefault();
        // 登録時に使用した認証の方法(google, facebook)を送信する
        GA.trackingSignUp(identityProvider);

        await Auth.snsRegister(identityProvider);
      } catch (error) {
        console.error(error);
      }
    },
    saveSignUpSteps: (props) => () => {
      const {
        holdSignUpSteps,
        form: {
          signUp: { values },
        },
      } = props;

      holdSignUpSteps(values);
    },
    submitRecapcha: (props) => (token) => {
      const { updateDisplayRecapchaError, updateRecapchaResult } = props;

      updateRecapchaResult(token);
      updateDisplayRecapchaError(false);
    },
    submitSignUp: (props) => async (immutableValues) => {
      const values = immutableValues.toJS();

      const {
        push,
        updateLoading,
        updateDisplayRecapchaError,
        postUser,
        recapchaResult,
        destroy,
      } = props;

      updateLoading(true);

      if (!recapchaResult) {
        // recapchaで不正解のとき処理を辞める。
        updateLoading(false);
        updateDisplayRecapchaError(true);
        return;
      }

      try {
        const result = await postUser(values);
        // eslint-disable-next-line
        if (!result.value.hasOwnProperty('id')) {
          throw new Error(result.value.message);
        }
        //NOTE 以下で、AWS Cognito認証などを行なっている
        const data = await Auth.signUp(values.email.trim(), values.password, {
          email: values.email.trim(),
        });
        // eslint-disable-next-line
        if (data.hasOwnProperty('message')) {
          throw new Error(data.message);
        } else {
          // パスワード認証, メール認証の場合、Passowrdを送信する
          GA.trackingSignUp('Password');

          // localStorageにuserを保存する。
          storeUser(values, push, updateLoading, destroy);
        }
      } catch (error) {
        const errorMessage = error?.message?.replace('Error: ', '');
        switch (errorMessage) {
          case 'user already exists':
            notify(
              'メールアドレスが既に存在している為、新規登録に失敗しました。また、Google、FacebookでFruiteSaleの会員登録済みの可能性があります。',
              'error',
              undefined,
              5
            );
            break;

          case 'duplicate username':
            notify(
              'ニックネームが既に存在している為、新規登録に失敗しました。',
              'error',
              undefined,
              5
            );
            break;

          case 'contain ban words':
            notify('不適切な表現が含まれています。', 'error', undefined, 5);
            break;

          default:
            notify(
              '新規登録に失敗しました。しばらく時間をおいて再度お試しください。',
              'error',
              undefined,
              5
            );
            break;
        }
        updateLoading(false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { props } = this;

      const {
        initialize,
        app: { signUpSteps },
      } = props;

      initialize(signUpSteps);
    },
  })
);

export default enhance;

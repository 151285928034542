import { DivLiner } from '@/components/Common';
import { assets, images, paths } from '@/config';
import * as React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { Header, Image } from 'semantic-ui-react';
import enhance from './enhancer';

function Footer() {
  const applyNavigation = () => {
    return (
      <>
        <div className="c__contents">
          <Header className="contents">コンテンツ</Header>

          {!window.isAuthed ? (
            <React.Fragment />
          ) : (
            <div className="link sell">
              <Link to={paths.sell}>出品する</Link>
            </div>
          )}
          <div className="link fishes">
            <Link to={paths.fishes.all}>出品商品一覧</Link>
          </div>
          <div className="link">
            <Link to={paths.common.fee}>離島料金</Link>
          </div>
        </div>
        <div className="c__guide">
          <Header className="guide">ご利用ガイド</Header>
          <div className="link terms">
            <Link to={paths.introduction.explain}>Fruit Saleについて</Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.fee}>サービス利用料について</Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.sell}>出品方法について</Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.account}>口座登録方法について</Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.change_sales_account}>
              口座・入金スケジュールの変更方法について
            </Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.sales_account}>Stripeについて</Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.howto_sales_account}>
              Stripeの登録方法について
            </Link>
          </div>
          {/* <div className="link terms">
            <Link to={paths.introduction.cooking}>
              処理方法、食中毒について
            </Link>
          </div> */}
          <div className="link terms">
            <Link to={paths.introduction.banner}>バナー使用について</Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.card}>セキュリティコードについて</Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.styrofoam}>
              発泡スチロール購入先について
            </Link>
          </div>
          <div className="link terms">
            <Link to={paths.introduction.prepayment}>
              元払い出品の送料について
            </Link>
          </div>
        </div>
        <div className="c__aboutus">
          <Header className="aboutus">Fruit Saleについて</Header>
          <div className="link">
            {/* <Link to={paths.common.company}>会社概要</Link> */}
            <a
              href={paths.common.company}
              target="_blank"
              rel="noopener noreferrer"
            >
              会社概要
            </a>
          </div>
          <div className="link terms">
            {/* <Link to={paths.policy.terms}>ご利用規約</Link> */}
            <a
              href={paths.common.terms}
              target="_blank"
              rel="noopener noreferrer"
            >
              ご利用規約
            </a>
          </div>
          <div className="link law">
            {/* <HashLink smooth to="/law#law">
              特定商取引に関する表記
            </HashLink> */}
            <a
              href={paths.common.law}
              target="_blank"
              rel="noopener noreferrer"
            >
              特定商取引に関する表記
            </a>
          </div>
          <div className="link privacy">
            {/* <HashLink smooth to="/law#privacy">
              プライバシーポリシー
            </HashLink> */}
            <a
              href={paths.common.privacy}
              target="_blank"
              rel="noopener noreferrer"
            >
              プライバシーポリシー
            </a>
          </div>
          <div className="link company">
            <Link to={paths.common.contact}>お問い合わせ</Link>
          </div>
          {/* <div className="link media_publication">
            <Link to={paths.common.mediaPublication}>メディア掲載</Link>
          </div> */}
          <div className="sns_lists flex">
            <a
              href="https://www.facebook.com/fruitsale.jp/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image src={assets.fish_facebook_icon} className="logo" />
            </a>
            <a
              href="https://www.instagram.com/fruitsale_jp/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image src={assets.fish_instagram_icon} className="logo" />
            </a>
          </div>
        </div>
        <div className="c__aboutus">
          <Header className="aboutus">グループサイト</Header>
          <div className="link">
            <a
              href={`https://fishsale.jp`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={`https://assets.fishsale.jp/logo/logo-02.png`}
                width={'120px'}
                style={{ display: 'inline-block' }}
              />
            </a>
          </div>
          <div className="link">
            <a
              href={`https://meatsale.jp`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={`https://meatsale.jp/assets/logo/logo-white.png`}
                width={'120px'}
                style={{ display: 'inline-block' }}
              />
            </a>
          </div>
          <div className="link">
            <a
              href={`https://vegetablesale.jp`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={`https://vegetablesale.jp/assets/logo/logo-white.png`}
                width={'120px'}
                style={{ display: 'inline-block' }}
              />
            </a>
          </div>
        </div>
      </>
    );
  };

  return (
    <footer className="app_footer">
      <div className="main flex">
        <div className="logo_inverted flex">
          <div className="flex">
            <Link to={paths.landing}>
              <Image src={images.logo_footer} />
            </Link>
          </div>
        </div>
        <DivLiner className="divider" />

        <Media
          query="(min-width: 768px)"
          render={() => (
            <div className="navigation grid">{applyNavigation()}</div>
          )}
        />
        <Media
          query="(max-width: 767px)"
          render={() => (
            <div className="navigation flex">{applyNavigation()}</div>
          )}
        />
      </div>

      <div className="copyright" style={{ textAlign: 'center' }}>
        <span>&copy;2022 Fruit Sale</span>

        <p style={{ textAlign: 'center', fontSize: 10, marginTop: '1em' }}>
          事業再構築
        </p>
      </div>
    </footer>
  );
}

export default enhance((props) => <Footer {...props} />);

import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { default as enhance } from './enhancer';

function Law() {
  return (
    <div className="app_law">
      <div id="law" className="grid_law">
        <div className="title_container">
          <Header as="h2" className="title">
            特定商取引に関する表記
          </Header>
        </div>
        <div className="contents">
          <div className="contents_container">
            <div className="contents-title">事業者</div>
            <div className="contents-text">FreshSpeed株式会社</div>
          </div>
          <div className="contents_container">
            <div className="contents-title">代表者</div>
            <div className="contents-text">代表取締役 長辻 博史</div>
          </div>
          <div className="contents_container">
            <div className="contents-title">ホームページ</div>
            <div className="contents-text">https://fruitsale.jp</div>
          </div>
          <div className="contents_container">
            <div className="contents-title">所在地</div>
            <div className="contents-text">
              〒550-0013
              <br />
              大阪市西区新町１丁目４番２６号６階
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">電話番号</div>
            <div className="contents-text">050-5357-6320</div>
          </div>
          <div className="contents_container">
            <div className="contents-title">役務の内容</div>
            <div className="contents-text">
              Fish
              Saleは出品商品（鮮商品）を競り落としたり、出品したりするための取引の機会を提供します。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">役務の対価</div>
            <div className="contents-text">
              入落札・出品にはシステム利用料がかかります。
              <br />
              出品商品を買う場合、当該出品商品を競り落とした金額及び出品商品の配送費用を負担する必要があります。
              <br />
              出品商品を出品した場合、当該出品商品が落札された場合に取引価格の10％をシステム利用料として負担する必要があります。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">出品商品の販売価格帯</div>
            <div className="contents-text">
              各出品商品に表記された価格に準じます。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">出品商品等の引き渡し時期</div>
            <div className="contents-text">
              出品商品の発送は、出品者の責任において行われますが、クレジット決済がなされた場合、当該情報は直ちに出品者に伝えられ、出品商品の発送を促します。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">出品商品代金の支払方法</div>
            <div className="contents-text">
              クレジットカード・デビットカード決済(一括)
              ※着払い指定の出品商品を落札された場合、配送費用を各配送業者指定の方法で出品商品到着時にお支払い（現金やクレジットカード等）
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">代金の支払時期</div>
            <div className="contents-text">即日決済</div>
          </div>
          <div className="contents_container">
            <div className="contents-title">
              出品商品代金以外に必要な費用・手数料
            </div>
            <div className="contents-text">
              （落札者の負担）出品商品により配送費用
              <br />
              （出品者の負担）出品商品が落札された場合、取引額に対する本サービス利用手数料10%
              <br />
              <span className="text-space" />
              配送料込みで出品した場合、郵送時の配送費用
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">返品・交換について</div>
            <div className="contents-text">
              お客様都合による返品は受け付けておりません。
              <br />
              万が一出品商品の欠陥、数量不足、滅失やその他瑕疵がある場合には、出品者の責任にて出品商品の返品又は交換を行うものとします。
              <br />
              不良品については出品者へチャット機能にて連絡を行ってください。
            </div>
          </div>
        </div>
        <div id="privacy" className="title_container">
          <Header as="h2" className="title">
            プライバシーポリシー
          </Header>
        </div>
        <div className="contents">
          <div className="contents_container">
            <div className="contents-title" />
            <div className="contents-text">
              FreshSpeed株式会社（以下、「当社」といいます。）は、当社が提供・運営する「フルーツセール（Fish
              Sale）」（以下,「本サービス」といいます。）における個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。ユーザーは、本サービスを利用するにあたり、本プライバシーポリシーに従って当社が個人情報を取扱うことに同意するものとします。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第1条（個人情報）</div>
            <div className="contents-text">
              「個人情報」とは、次に掲げる情報を言います。
              <br />
              ①
              個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報
              <br />② ユーザーがFacebook
              Connect及びGoogleアカウントを使用することを許諾し、Facebook及びGoogleアカウントのID及びパスワードを入力した場合に取得できる氏名、ニックネーム、メールアドレス、ID、誕生日、性別、プロフィール画像、Facebook及びGoogleアカウント上の友達情報、その他のお客様のFacebook及びGoogleアカウント上で登録されている情報を指します。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第2条（履歴情報及び特性情報）</div>
            <div className="contents-text">
              「履歴情報及び特性情報」とは、第１条に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた出品商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第3条（情報の収集方法）</div>
            <div className="contents-text">
              当社は、個人情報及び履歴情報及び特性情報（以下、併せて「取得情報」といいます。）を以下に掲げる方法により収集させて頂く場合があります。
              <br />
              ①本サービス上で取得情報をユーザー自身に入力して頂く方法
              <br />
              ②業務提携先（情報提供元、広告主、広告配信先などを含みます。）及び第三者などから間接的に収集する方法（ユーザーがFacebook
              Connect又はGoogleアカウントを使用した場合、ID及びパスワードを入力していただき、Facebook及びGoogleアカウントからユーザーの登録情報を収集する方法を含みます）
              <br />
              ③ユーザーから電子メール、郵便、書面等で情報を収集する方法
              <br />
              ④その他、本サービスを提供するに際し当社が必要と認める方法
              <br />
              ⑤ユーザーが本サービスを利用した場合に、購入した出品商品、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報を収集する方法
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">
              第4条（個人情報を収集・利用する目的）
            </div>
            <div className="contents-text">
              当社が個人情報を収集・利用する目的は，以下のとおりです。
              <br />
              ①
              ユーザーに自身の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために情報を表示する目的
              <br />
              ② ユーザーにお知らせや連絡をする目的
              <br />
              ③ ユーザーに本サービスを提供する目的
              <br />
              ④ ユーザーによるデータ入力を簡便にする目的
              <br />
              ⑤ ユーザーの本人確認を行う目的
              <br />
              ⑥
              ユーザーに本サービス利用におけるご請求、お支払い、及びその確認をする目的
              <br />
              ⑦ ユーザーに本サービス利用における落札の確認などをする目的
              <br />
              ⑧
              ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送する目的
              <br />
              ⑨
              本サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用を防止する目的
              <br />
              ⑩ ユーザーからのお問い合わせに対応する目的
              <br />
              ⑪ 本サービス運営上のトラブル解決の目的
              <br />⑫ 上記の利用目的に付随する目的
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第5条（個人情報の管理）</div>
            <div className="contents-text">
              当社にて個人情報を管理・利用する業務に従事する者は、ユーザーの個人情報について厳重に管理を行い、個人情報への不当なアクセス、紛失、漏洩、改ざん等が生じないように当該個人情報の取扱いに十分な注意を払います。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第6条（個人情報の第三者提供）</div>
            <div className="contents-text">
              １
              当社は、次に掲げる場合を除いて、予めユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
              <br />
              ① 法令に基づく場合
              <br />
              ②
              人の生命、身体または財産を保護するために必要であり、かつ本人の同意を得ることが困難である場合
              <br />
              ③
              公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
              <br />
              ④
              国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
              <br />
              ２
              前項の定めに関わらず、次に掲げる場合は、「第三者」には該当しないものとします。
              <br />
              ①
              当社が、決済代行業者、業務委託先その他の外部受託者に対し、本サービス提供のために必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
              <br />
              ②
              合併、会社分割、事業譲渡その他の事由によりユーザーの個人情報を含む事業の承継がなされる場合
              <br />③
              特定の者との間で共同して利用される個人情報が当該特定の者に提供される場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ、ユーザーに通知し、またはユーザーが容易に知り得る状態に置いている場合
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第7条（情報の取扱いの委託）</div>
            <div className="contents-text">
              当社は、業務の一部を他社または個人に委託しており、個人情報の全部または一部の取扱いを業務委託先に委託することがあります。委託業者は、業務遂行のために必要な個人情報を利用しますが、それ以外の目的で利用することはありません。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">
              第8条（個人情報の開示及び確認等）
            </div>
            <div className="contents-text">
              １
              当社は、ユーザーから個人情報の開示を求められた場合には、ユーザーに対し遅滞なく、当社所定の方法により当該個人情報を開示します。ただし、開示することにより次の各号のいずれかに該当する場合は、その全部または一部を開示しないことがあります。
              <br />
              ①
              本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
              <br />
              ② 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
              <br />
              ③ 他の法令に違反することとなる場合
              <br />
              ２
              当社は、前項但書の開示しない決定をした場合または当該個人情報が存在しないときには、ユーザーにその旨を遅滞なく通知します。
              <br />３
              第１項の定めにかかわらず、履歴情報及び特性情報などの個人情報以外の情報については、原則として開示いたしません。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">
              第9条（個人情報の訂正及び削除等）
            </div>
            <div className="contents-text">
              ユーザーは、当社の保有する自己の個人情報の内容が事実と異なる情報である場合には、当社所定の方法により、当該個人情報の訂正または削除を行うことができます。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第10条（個人情報の利用停止等）</div>
            <div className="contents-text">
              当社は、本人から、個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨を本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合、その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">
              第11条（プライバシーポリシーの変更）
            </div>
            <div className="contents-text">
              当社は、プライバシーポリシーの適宜改善及び更新に努めます。
              本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
            </div>
          </div>
          <div className="contents_container">
            <div className="contents-title">第12条（お問い合わせ窓口）</div>
            <div className="contents-text bottom">
              本ポリシーに関するお問いわせは、下記の窓口までお願いいたします。
              <br />
              ・ 住所: 〒550-0013 大阪市西区新町１丁目４番２６号６階
              <br />
              ・ 社名: Fresh Speed株式会社
              <br />
              ・ お問い合わせ先：https://fruitsale.jp/contact
              <br />・ 2019年8月9日制定
              <div className="law_end">以上</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <Law {...props} />);

export default [
  {
    key: '1',
    value: 'all',
    text: '全ての商品',
  },
  {
    key: '2',
    value: 'canBidding',
    text: '入札可能な商品',
  },
  {
    key: '3',
    value: 'onDisplay',
    text: '未入札の商品',
  },
  {
    key: '4',
    value: 'bidding',
    text: 'オークション中の商品',
  },
  {
    key: '5',
    value: 'alreadyCompletedBidding',
    text: '落札済みの商品',
  },
  {
    key: '6',
    value: 'biddingComplete',
    text: 'オークションが終了した商品',
  },
];

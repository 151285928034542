import * as React from 'react';
import { MediaPublication } from '@/components/Common';

function IntroductionExplain() {
  return (
    <div className="app_explain">
      <div className="wrapper">
        <div className="main_title">
          Fruit Saleって
          <span className="less_than_sm">
            <br />
          </span>
          どんなサービス？
        </div>
        <MediaPublication />
        <div className="contents">
          <div className="sub_title">1. 一般流通との違いは？</div>
          <img
            className="more_than_md"
            src="https://fruitsale.jp/assets/Explain_pc/explain_top_pc.jpg"
            loading="lazy"
            decoding="async"
          />
          <img
            className="less_than_sm"
            src="https://fruitsale.jp/assets/Explain_mobile/img_01_mobile.jpg"
            loading="lazy"
            decoding="async"
          />
          <img
            className="less_than_sm"
            src="https://fruitsale.jp/assets/Explain_mobile/img_02_mobile.jpg"
            loading="lazy"
            decoding="async"
          />
        </div>
        <div className="contents">
          <div className="sub_title">2. Fruit Saleで出品すると？</div>
          <img
            className="more_than_md"
            src="https://fruitsale.jp/assets/Explain_pc/explain_middle_pc.jpg"
            loading="lazy"
            decoding="async"
          />
          <img
            className="less_than_sm"
            src="https://fruitsale.jp/assets/Explain_mobile/img_05_mobile.jpg"
            loading="lazy"
            decoding="async"
          />
        </div>
        <div className="contents">
          <div className="sub_title">3. Fruit Saleのメリットって？</div>
          <img
            className="more_than_md"
            src="https://fruitsale.jp/assets/Explain_pc/explain_bottom_pc.jpg"
            loading="lazy"
            decoding="async"
          />
          <img
            className="less_than_sm"
            src="https://fruitsale.jp/assets/Explain_mobile/img_03_mobile.jpg"
            loading="lazy"
            decoding="async"
          />
          <img
            className="less_than_sm"
            src="https://fruitsale.jp/assets/Explain_mobile/img_04_mobile.jpg"
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
    </div>
  );
}

export default IntroductionExplain;

import { images, paths } from '@/config';
import React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { NavBar } from './NavBar';
import enhance from './enhance';

function Header() {
  if (!window.isAuthed) {
    return (
      <>
        <Media
          query="(max-width: 767px)"
          render={() => (
            <div className="group-link -sp">
              <p className="group-link__heading">姉妹サイト</p>
              <ul className="group-link__list">
                <li className="group-link__item">
                  <a
                    href={`https://fishsale.jp`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={`https://assets.fishsale.jp/logo/logo.png`}
                      alt={'Fish Sale'}
                    />
                  </a>
                </li>
                <li className="group-link__item">
                  <a
                    href={`https://meatsale.jp`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={`https://meatsale.jp/assets/logo/logo.png`}
                      alt={'Meat Sale'}
                    />
                  </a>
                </li>
                <li className="group-link__item">
                  <a
                    href={`https://vegetablesale.jp`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={`https://vegetablesale.jp/assets/logo/logo.png`}
                      alt={'Vegetable Sale'}
                    />
                  </a>
                </li>
              </ul>
            </div>
          )}
        />
        <header className="app__header">
          <div className="app__header-inner">
            <div className="wrapper">
              <Media
                query="(min-width: 768px)"
                render={() => (
                  <Link to={paths.landing} className="logo">
                    <Image src={images.logo_header} className="logo" />
                  </Link>
                )}
              />
              <Media
                query="(max-width: 767px)"
                render={() => (
                  <Link to={paths.landing} className="logo">
                    <Image src={images.logo_header_mobile} className="logo" />
                  </Link>
                )}
              />

              <Media
                query="(min-width: 768px)"
                render={() => (
                  <div className="group-link -pc">
                    <p className="group-link__heading">姉妹サイト</p>
                    <ul className="group-link__list">
                      <li className="group-link__item">
                        <a
                          href={`https://fishsale.jp`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={`https://assets.fishsale.jp/logo/logo.png`}
                            alt={'Fish Sale'}
                          />
                        </a>
                      </li>
                      <li className="group-link__item">
                        <a
                          href={`https://meatsale.jp`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={`https://meatsale.jp/assets/logo/logo.png`}
                            alt={'Meat Sale'}
                          />
                        </a>
                      </li>
                      <li className="group-link__item">
                        <a
                          href={`https://vegetablesale.jp`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={`https://vegetablesale.jp/assets/logo/logo.png`}
                            alt={'Vegetable Sale'}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              />
              <NavBar />
            </div>
          </div>
        </header>
      </>
    );
  }

  return (
    <>
      <Media
        query="(max-width: 767px)"
        render={() => (
          <div className="group-link -sp">
            <p className="group-link__heading">姉妹サイト</p>
            <ul className="group-link__list">
              <li className="group-link__item">
                <a
                  href={`https://fishsale.jp`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={`https://assets.fishsale.jp/logo/logo.png`}
                    alt={'Fish Sale'}
                  />
                </a>
              </li>
              <li className="group-link__item">
                <a
                  href={`https://meatsale.jp`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={`https://meatsale.jp/assets/logo/logo.png`}
                    alt={'Meat Sale'}
                  />
                </a>
              </li>
              <li className="group-link__item">
                <a
                  href={`https://vegetablesale.jp`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={`https://vegetablesale.jp/assets/logo/logo.png`}
                    alt={'Vegetable Sale'}
                  />
                </a>
              </li>
            </ul>
          </div>
        )}
      />
      <header className="app__header">
        <div className="app__header-inner">
          <div className="wrapper">
            <Media
              query="(min-width: 768px)"
              render={() => (
                <Link to={paths.landing} className="logo">
                  <Image src={images.logo_header} className="logo" />
                </Link>
              )}
            />
            <Media
              query="(max-width: 767px)"
              render={() => (
                <Link to={paths.landing} className="logo">
                  <Image src={images.logo_header_mobile} className="logo" />
                </Link>
              )}
            />

            <Media
              query="(min-width: 768px)"
              render={() => (
                <div className="group-link -pc">
                  <p className="group-link__heading">姉妹サイト</p>
                  <ul className="group-link__list">
                    <li className="group-link__item">
                      <a
                        href={`https://fishsale.jp`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={`https://assets.fishsale.jp/logo/logo.png`}
                          alt={'Fish Sale'}
                        />
                      </a>
                    </li>
                    <li className="group-link__item">
                      <a
                        href={`https://meatsale.jp`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={`https://meatsale.jp/assets/logo/logo.png`}
                          alt={'Meat Sale'}
                        />
                      </a>
                    </li>
                    <li className="group-link__item">
                      <a
                        href={`https://vegetablesale.jp`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={`https://vegetablesale.jp/assets/logo/logo.png`}
                          alt={'Vegetable Sale'}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            />
            <NavBar />
          </div>
        </div>
      </header>
    </>
  );
}

export default enhance((props) => <Header {...props} />);

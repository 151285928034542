import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import notify from '@/migration/notification';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { appAction, usersAction, salesAction } from '@/actions';
import { myPageEdit as validate } from '@/validates';
import {
  postUserImageUpload,
  getMe,
  createNewEmail,
  postUserShipping,
  searchByPostal,
  deleteUserShipping,
} from '@/apis';
import { resizeDroppedImages } from '@/utils';
import loadImage from 'blueimp-load-image';

import { changePassword } from '@/utils/auth';

import { paths } from '@/config';
import { Auth } from 'aws-amplify';
import storage from 'store';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...appAction,
      ...usersAction,
      ...salesAction,
      notify,
    },
    dispatch
  );

const enhance = compose(
  setDisplayName('MyPageEdit'),
  reduxForm({
    form: 'myPageEditForm',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('preSaleImage', 'updatePreSaleImage', ''),
  withState('viewSaleImage', 'updateViewSaleImage', ''),
  withState('isImageLoading', 'updateImageLoading', false),
  withState('openResetEmailModal', 'updateOpenEmailModal', false),
  withState('openResetPasswordModal', 'updateOpenPasswordModal', false),
  withState('loading', 'setLoading', false),
  withHandlers({
    // プロフィール編集
    submitProfile: (props) => async (immutableValues) => {
      const {
        displayName,
        isCompany,
        companyName,
        companyAddress,
        fishingBoat,
        prefecture,
        introduction,
        thumnailImageUrl,
        myshippingdata,
        shippingProvider,
        isBidPopup,
        isNextAutoBid,
      } = immutableValues.toJS();

      const { change } = props;

      const { putUser, getMe, preSaleImage, getUserShipping } = props;

      try {
        await getMe();

        const userId = props.users.mydata.id;
        const result = await putUser(userId, {
          displayName,
          isCompany,
          companyName,
          companyAddress,
          prefecture,
          fishingBoat,
          introduction,
          shippingProvider,
          isBidPopup,
          isNextAutoBid,
          thumnailImageUrl:
            preSaleImage ||
            thumnailImageUrl ||
            props.users.mydata.thumnailImageUrl,
        });

        // eslint-disable-next-line
        if (!result.value.hasOwnProperty('id')) {
          throw new Error(result.value.message);
        }

        if (myshippingdata.length > 5) {
          throw new Error('送付先住所の登録可能な数は5つまでです。');
        }

        const unregisteredShippingData = myshippingdata.filter(
          ({ id }) => id === undefined
        );

        let isError = false;
        for (let i = 0; i < unregisteredShippingData.length; i++) {
          const { addressAfter, addressBefore, ...myshippingdata2 } =
            unregisteredShippingData[i];
          try {
            await postUserShipping(userId, {
              ...myshippingdata2,
              address: `${addressBefore}${addressAfter}`,
            });
          } catch (error) {
            isError = true;
          }
        }

        await getMe();

        window.scrollTo({ left: 0, top: 0, behavior: 'instant' });

        if (isError) throw new Error('プロフィールの編集に失敗しました。');

        notify('プロフィールを編集しました。', 'info', undefined, 5);

        const {
          action: { payload },
        } = await getUserShipping(props.users.mydata.id);

        payload.forEach(({ id }, index) => {
          change(`myshippingdata[${index}].id`, id);
        });
      } catch (error) {
        switch (error.message) {
          case 'duplicate username':
            notify(
              'ニックネームが既に存在している為、編集に失敗しました。',
              'error',
              undefined,
              5
            );
            break;

          case 'contain ban words':
            notify('不適切な表現が含まれています。', 'error', undefined, 5);
            break;

          case 'address is associated with a sale':
            notify(
              '入札中もしくは落札済みの取引がある為、編集できません。落札後10日以降、編集可能となります。',
              'error',
              undefined,
              5
            );
            break;

          default:
            notify('プロフィールの編集に失敗しました。', 'error', undefined, 5);
            console.error(error);
            break;
        }
        window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
      }
    },

    // 住所の検索
    searchAddress: (props) => async (index) => {
      const {
        form: { myPageEditForm },
        change,
      } = props;

      const { myshippingdata } = myPageEditForm.values;

      searchByPostal({
        postal: `${myshippingdata[index].top_post_number}${myshippingdata[index].bottom_post_number}`,
      }).then(
        ({
          data: {
            response: { location },
          },
        }) => {
          if (!location) {
            change(`myshippingdata[${index}].addressBefore`, '');
            change(`myshippingdata[${index}].post_number`, '');
            return;
          }

          const { city, prefecture } = location[0];
          change(
            `myshippingdata[${index}].addressBefore`,
            `${prefecture}${city}`
          );
          change(
            `myshippingdata[${index}].post_number`,
            `${myshippingdata[index].top_post_number}-${myshippingdata[index].bottom_post_number}`
          );
        }
      );
    },

    /** 住所の削除 */
    deleteShippingData: () => async (shippingId) => {
      try {
        await deleteUserShipping(shippingId);
        notify('住所の削除に成功しました。', 'info', undefined, 5);

        setTimeout(() => {
          window.location.reload();
        }, 800);
      } catch (error) {
        notify(
          '選択した住所は関連した競りの終了10日後より削除可能です。',
          'error',
          undefined,
          5
        );
      }
    },

    // リセットモーダルの開閉を制御
    handleResetPasswordModal: (props) => () => {
      props.updateOpenPasswordModal(!props.openResetPasswordModal);
    },

    drop: (props) => async (acceptFiles) => {
      const { updateViewSaleImage, updatePreSaleImage, updateImageLoading } =
        props;

      const promises = acceptFiles.map(async (file) => {
        const newFile = new Promise((resolve) => {
          loadImage(
            file,
            async (img) => {
              img.toBlob((blob) => {
                const blobUrl = URL.createObjectURL(blob);
                resolve(
                  Object.assign(file, {
                    original: blobUrl,
                    thumb: blobUrl,
                    cropBoxData: {},
                  })
                );
              });
            },
            {
              orientation: true,
              meta: true,
              contain: true,
              maxWidth: 1000,
            }
          );
        });

        return newFile;
      });

      updateImageLoading(true);
      const nextFiles = await Promise.all(promises);
      updateViewSaleImage(nextFiles[0].thumb);

      const images = await resizeDroppedImages(nextFiles);

      const me = await getMe();

      try {
        const result = await postUserImageUpload({
          image: images[0],
          userId: me.id,
        });

        const imageUrl = result.url;
        updatePreSaleImage(imageUrl);
      } catch (err) {
        switch (err.message) {
          case 'ContainInappropriateImages':
            notify('画像に不適切な表現が含まれています', 'error', undefined, 5);
            break;

          default:
            break;
        }
        updateViewSaleImage(null);
      } finally {
        updateImageLoading(false);
      }
    },

    /** メールアドレス変更 */
    resetEmailSubmit: (props) => async () => {
      const {
        setLoading,
        updateOpenEmailModal,
        users: { mydata },
        form: { emailResetForm },
      } = props;

      try {
        const { newEmail } = emailResetForm.values;
        setLoading(true);

        const body = {
          new_identity_code: newEmail,
        };
        await createNewEmail(mydata.id, body);

        storage.set('userId', mydata.id);
        updateOpenEmailModal(false);
        notify(
          '新しいメールアドレスにメールをお送りしました。そちらから変更を完了してください。',
          'info',
          undefined,
          5
        );
      } catch (error) {
        if (emailResetForm.values === undefined) {
          notify('メールアドレス変更に失敗しました。', 'error', undefined, 5);
        } else {
          //TODO 一旦エラーを決め打ち
          notify(
            'FacebookもしくはGoogleでログインしているユーザーのため、メールアドレスは変更できません。',
            'error',
            undefined,
            5
          );
        }
      } finally {
        setLoading(false);
      }
    },

    /** パスワード変更 */
    resetPassword: (props) => async () => {
      const {
        setLoading,
        updateOpenPasswordModal,
        form: { passwordResetForm },
      } = props;

      try {
        const { oldPassword, newPassword } = passwordResetForm.values;
        setLoading(true);

        await changePassword(oldPassword, newPassword);

        updateOpenPasswordModal(false);

        await Auth.signOut();
        storage.clearAll();
        window.isAuthed = false;
        props.history.push(paths.before.signin);
        notify(
          'パスワードを変更しました。再度、ログインして下さい。',
          'info',
          undefined,
          5
        );
      } catch (error) {
        if (passwordResetForm.values === undefined) {
          notify('パスワード変更に失敗しました。', 'error', undefined, 5);
        } else {
          //TODO 一旦エラーを決め打ち
          notify(
            'FacebookもしくはGoogleでログインしているユーザーのため、メールアドレスは変更できません。',
            'error',
            undefined,
            5
          );
        }
      } finally {
        setLoading(false);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { initialize, getMe, getUserShipping } = this.props;

      await getMe();
      await getUserShipping(this.props.users.mydata.id);

      const addressBefores = await Promise.all(
        this.props.users.myshippingdata.map(
          async ({ post_number }) =>
            new Promise((resolve) => {
              searchByPostal({
                postal: parseInt(post_number.replace(/-/g, ''), 10),
              }).then(
                ({
                  data: {
                    response: { location },
                  },
                }) => {
                  if (!location) {
                    resolve('');

                    return;
                  }

                  const { city, prefecture } = location[0];

                  resolve(`${prefecture}${city}`);
                }
              );
            })
        )
      );

      initialize({
        isBidPopup: this.props.users.mydata.isBidPopup,
        introduction: this.props.users.mydata.introduction,
        displayName: this.props.users.mydata.displayName,
        prefecture: this.props.users.mydata.prefecture,
        companyName: this.props.users.mydata.company,
        isCompany: this.props.users.mydata.isCompany,
        companyAddress: this.props.users.mydata.companyAddress,
        fishingBoat: this.props.users.mydata.fishingBoat,
        shippingProvider: this.props.users.mydata.shippingProvider,
        isNextAutoBid: this.props.users.mydata.isNextAutoBid,
        myshippingdata: this.props.users.myshippingdata.map(
          (
            {
              address,
              delivery_time,
              full_name,
              id,
              phone_number,
              post_number,
            },
            index
          ) => {
            const addressBefore = addressBefores[index];
            const postNumber = post_number.split('-');

            return {
              addressBefore,
              delivery_time,
              full_name,
              id,
              phone_number,
              post_number,
              top_post_number: postNumber.length ? postNumber[0] : '',
              bottom_post_number: postNumber.length ? postNumber[1] : '',
              addressAfter: address.replace(addressBefore, ''),
            };
          }
        ),
      });
    },
  })
);

export default enhance;
